$primaryColor: #07addd;
/*#16c1e2*/
$buttonPrimaryColor: #94ba33;
$buttonPrimaryTextColor: #ffffff;
/*Dashboard*/
$dashboardBlockHeading: #002c5b;
$dashboardBlockRadius: 25px;
$dashboardBlockBackgroundGrey: #f4f4f4;
$dashboardBlockSecondaryColor: #00a3d9;

.d-none {
  display: none;
}

.d-block {
  display: block;
}
.blur-text {
  color: #7f7f7f;
}
input:focus-visible {
  outline: none;
}
button,
a {
  cursor: pointer !important;
}
@font-face {
  font-family: poppins-regular;
  src: url(../fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: poppins_bold;
  src: url(../fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: Rubik-Bold;
  src: url(../fonts/Rubik-Bold.ttf);
}

@font-face {
  font-family: Rubik-Regular;
  src: url(../fonts/Rubik-Regular.ttf);
}

body {
  margin: 0;
  background: #fbfbfb;
  font-family: "poppins-regular";
  overflow: auto;
  height: 100%;

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #a8afb2;
    border-radius: 5px;
    height: 5px !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #92989b;
  }
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none;
}

.mandatoryField {
  color: #f41919;
  display: inline;
  font-size: 13px;
}

.mainContainer {
  //   display: flex;
  width: 100%;

  .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
    padding: 0;
  }

  .css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
    padding: 0;
  }
}

.mainContent {
  display: flex;
  flex: 1;
  padding: 20px 0 20px 20px;
}

.billingContent {
  display: flex;
  flex: 1;
}

ul {
  padding: 0;
}

li {
  list-style: none;
}

// a {
//   text-decoration: none;
// }

.pointer {
  cursor: pointer;
}

.d-flex {
  display: flex;
}

.download_button {
  color: #94ba33;
  text-decoration: underline !important;
}

.clearfix {
  clear: both;
}

.job-type-div {
  display: flex;
  align-items: center;
}

.job-type-fullTime {
  height: 8px;
  width: 8px;
  background-color: #a0de00;
  border-radius: 50%;
  margin-right: 7px;
}

.job-type-Intern {
  height: 8px;
  width: 8px;
  background-color: #f5980b;
  border-radius: 50%;
  margin-right: 7px;
}

.date_picker {
  background: none;
  border-radius: 25px;
  padding: 10px;
  border: none;
}

/*****Loading****/
.loading {
  display: flex;
  justify-content: center;
  margin-top: 250px;
}

@media screen and (max-width: 1440px) {
  body {
    font-size: 13px !important;
    // border: 2px solid red;
  }
  .attendancePrograssBlock {
    .MuiBox-root {
      grid-column: span 3;
    }
  }
  .hrmsEmployeeDashboardSection {
    .employeeDashboardLiftSideContainer {
      .jobApplicationSummaryBlock {
        .donutchart {
          width: 360px !important;
        }
        .totalAmount {
          left: 33px !important;
        }
        ul {
          .legend {
            width: 185px;
          }
        }
      }
    }
    .employeeDashboardRightSideContainer {
      .todayLeavesContainer {
        .donutchart {
          width: 360px !important;
        }
      }
    }
  }
  .loginBackground {
    .loginLeftContent {
      h1 {
        margin: 10px 0 20px !important;
        font-size: 65px !important;
      }
    }
  }

  .loginPatternOne {
    .loginContainer {
      .loginButton {
        margin-top: 40px !important;
      }
    }
  }

  .drawerOpen {
    width: 18% !important;
    z-index: 999;
  }

  aside.drawerOpen > .footerBlock {
    // border: 1px solid red;
  }

  .mainOpen {
    margin-left: 18% !important;
  }

  aside {
    .side-navigation-panel-select-option {
      font-size: 13px !important;

      img {
        width: 20px !important;
        height: 20px !important;
      }
    }

    .side-navigation-panel-select-inner-option-text {
      font-size: 11px !important;
      margin: 10px 0 10px 30px !important;
    }
  }
  .consoleHeadingText {
    font-size: 14px !important;
  }
  .footerBlock {
    position: inherit;
    bottom: 0;
  }
}

@media (min-width: 1080px) {
  .footerBlock {
    // position: absolute;
    bottom: 0;
    width: 81%;
  }
}

@import "./login.scss";
@import "./leftSidebar.scss";
@import "./innerNavigation.scss";
@import "./Header.scss";
@import "./FormElements.scss";
@import "./Dashboard";
@import "./Buttons.scss";
@import "./PlaceHolder.scss";
@import "./RadioButton.scss";
@import "./TabsNavigation.scss";
@import "./customDropdown.scss";
@import "./Checkbox.scss";
@import "./VideoGifContainer.scss";
@import "./Grid.scss";
@import "./DashboardCard.scss";
@import "./lineChart.scss";
@import "./Popup.scss";
@import "./AutoCompleteSelect.scss";
@import "./CustomizeButtonView.scss";
@import "./CreateIndentAddItems.scss";
@import "./CreateIndent.scss";
@import "./CreateInwardEntry.scss";
@import "./InwardAddDetails.scss";
@import "./InwardEntryList.scss";
@import "./ColumnFilter.scss";
@import "./CreatePurchaseOrder.scss";
@import "./HRMSDashboard.scss";
@import "./PreviewStyles.scss";
@import "./Search.scss";
@import "./LeaveButton.scss";
@import "./MultiToggleButton.scss";
@import "./EmployeeDetailsCard.scss";
@import "./AddingNewEmployee.scss";
@import "./AttendancePopup.scss";
@import "./InputCounter.scss";
@import "./CommonPopup.scss";
@import "./OtpInputComponent.scss";
@import "./AlertModel.scss";
@import "./PayslipPopup.scss";
@import "./NotificationPopup.scss";
@import "./Onboarding.scss";
@import "./OfferLetter.scss";
@import "./CommonTable.scss";
@import "./Status.scss";
@import "./MonthandYearPicker.scss";
@import "./LeaveRequestCard.scss";
@import "./WelcomeLetter.scss";
@import "./changePassword.scss";
@import "./FilterComponent.scss";
@import "./UserManagementPopup.scss";
@import "./HolidayCalendar.scss";
@import "./Footer.scss";
@import "./FormModal.scss";
@import "./Gst_Credentials.scss";

.headingAndProgressbar {
  display: flex;
  justify-content: space-between;
  position: relative;
  line-height: 45px;

  p.organizationField {
    margin: 0;
    color: #7f7f7f;
    font-size: 12px;
    margin-left: 28px;
  }

  .progressBarBgContainer {
    width: 25%;
    position: absolute;
    top: -45px;
    right: 0;

    p {
      margin: 0;
      font-size: 13px;
      color: #7f7f7f;
      line-height: 25px;
    }

    p.percentage {
      margin-top: -7px;
      font-size: 12px;
      margin-left: 10px;
    }

    .progressBarBg {
      background-color: #ededed;
      height: 10px;
      border-radius: 20px;
      width: 80%;
      display: flex;

      .bar {
        width: 0;
        height: 10px;
        transition: width 0.3s;
        background: #a0de00;
        border-radius: 20px;
      }
    }
  }
}

@import "./Swal.scss";

/********HRMS*****************/
.formulaTable {
  // overflow-y: auto;
  border: 1px solid #006286;
  border-radius: 10px 10px 0 0;
  // max-height: 415px;
  // min-height: 405px;
  // background-color: #007fad;
  tbody {
    display: block;
    min-height: 350px;
    max-height: 350px;
    overflow: auto;
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
      scrollbar-width: thin;
    }

    &::-webkit-scrollbar {
      width: 9px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #ededed;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a8afb2;
      border-radius: 20px;
      height: 6px;
      border: transparent;
    }
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    td {
      white-space: initial !important;
      overflow-wrap: break-word;
      .closeButtonForInput {
        align-items: center !important;
        height: inherit !important;
      }
    }
  }
  // thead {
  //   width: calc(100% - 8px);
  // }

  .formulaTableTbody {
    height: 200px;
    overflow-y: auto;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar {
    width: 9px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a8afb2;
    border-radius: 20px;
    height: 6px;
    border: transparent;
  }
}

/*******************Loading*********************/

.loader-wrapper {
  width: 100%;

  height: 100%;

  position: fixed;

  top: 0;

  left: 0;

  background-color: #000000;

  display: grid;

  place-items: center;

  opacity: 0.5;

  z-index: 1;
}

.loader {
  border: 5px solid #e3e3e3;

  border-top: 5px solid #3498db;

  border-radius: 50%;

  width: 50px;

  height: 50px;

  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
/************************************************************************/
.fc-allow-mouse-resize {
  // border: 1px solid red;
  width: 100px;
  height: 100px;
}
/*MR Popup*/

.mr_modal {
  width: 50%;
  background-color: white;
  border-radius: 10px;
  z-index: 0.5;
  padding: 20px;
  .button {
    margin: 0 5px;
  }
}
/*************************Number arrow hiding***************************/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

.searchBlock {
  button {
    background: #c9e2ec;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #007fad;
    height: 38px;
    margin-left: 10px;
    min-width: 40px !important;
  }
  #search-bar-0 {
    background: url(../images/searchIcon.png);
    background-position: right 6px bottom 0px;
    background-repeat: no-repeat;
    background-size: contain;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #007fad;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #007fad;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #007fad;
    }
  }
  input[type="text"] {
    background: none;
    background: #c9e2ec;
    height: 20px;
    font-size: 15px;
    display: inline-block;
    border: none;
    outline: none;
    color: #007fad;
    padding: 0px;
    padding-right: 30px;
    width: 0px;
    transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
    cursor: pointer;
  }

  //   input[type="text"]:focus:hover {
  //     border-bottom: 1px solid #bbb;
  //   }

  input[type="text"]:focus {
    width: 150px;
    z-index: 1;
    cursor: text;
  }
  //   input[type="submit"] {
  //     height: 67px;
  //     width: 63px;
  //     display: inline-block;
  //     color: red;
  //     // float: right;
  //     background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADNQTFRFU1NT9fX1lJSUXl5e1dXVfn5+c3Nz6urqv7+/tLS0iYmJqampn5+fysrK39/faWlp////Vi4ZywAAABF0Uk5T/////////////////////wAlrZliAAABLklEQVR42rSWWRbDIAhFHeOUtN3/ags1zaA4cHrKZ8JFRHwoXkwTvwGP1Qo0bYObAPwiLmbNAHBWFBZlD9j0JxflDViIObNHG/Do8PRHTJk0TezAhv7qloK0JJEBh+F8+U/hopIELOWfiZUCDOZD1RADOQKA75oq4cvVkcT+OdHnqqpQCITWAjnWVgGQUWz12lJuGwGoaWgBKzRVBcCypgUkOAoWgBX/L0CmxN40u6xwcIJ1cOzWYDffp3axsQOyvdkXiH9FKRFwPRHYZUaXMgPLeiW7QhbDRciyLXJaKheCuLbiVoqx1DVRyH26yb0hsuoOFEPsoz+BVE0MRlZNjGZcRQyHYkmMp2hBTIzdkzCTc/pLqOnBrk7/yZdAOq/q5NPBH1f7x7fGP4C3AAMAQrhzX9zhcGsAAAAASUVORK5CYII=)
  //       center center no-repeat;
  //     text-indent: -10000px;
  //     border: none;
  //     position: absolute;
  //     top: 0;
  //     right: 0;
  //     z-index: 2;
  //     cursor: pointer;
  //     opacity: 0.4;
  //     cursor: pointer;
  //     transition: opacity 0.4s ease;
  //   }

  input[type="submit"]:hover {
    opacity: 0.8;
  }
}
.MuiDialog-scrollPaper {
  .MuiPaper-elevation {
    z-index:999;
  }
}

.customizeViewContainer {
  background: #ffffff;
  position: absolute;
  right: 0;
  padding: 20px;
  border-radius: 15px;
  width: 200px;
  top: 50px;
  right: 5%;
  box-shadow: 0px -3px 20px -6px darkslategrey;
  &:before {
    content: " ";
    height: 0;
    position: absolute;
    width: 0;
    top: -20px;
    right: 40px;
    border: 10px solid transparent;
    border-bottom-color: #ffffff;
  }
  .customizeViewContent {
    display: block;
    overflow-y: auto;
    height: 175px;
    &::-webkit-scrollbar {
      width: 5px;
      height:5px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #007fad;
      border-radius: 5px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #bdbdbd;
    }
  }
  // div{
  //     display: flex;
  //     align-items: center;
  //     font-size: 13px;
  //     line-height: 35px;
  //     color: #8f9592;
  // }
  input[type="checkbox"] {
    margin-right: 10px;
  }
  input[type="checkbox"] {
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #acc4d1;
    padding: 9px;
    border-radius: 5px;
    display: inline-block;
    position: relative;
  }
  input[type="checkbox"]:checked {
    background-color: #004b73;
    border: 2px solid #004b73;
    color: #ffffff;
  }
  input[type="checkbox"]:checked:before {
    position: absolute;
    left: -3px;
    top: 38%;
    height: 45%;
    width: 2px;
    background-color: #ffffff;
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
  }
  input[type="checkbox"]:checked:after {
    position: absolute;
    left: -3px;
    bottom: 3px;
    height: 2px;
    width: 80%;
    background-color: #ffffff;
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
  }
}
.customizeViewTable {
  table {
    th:first-child {
      border-right: 1px solid #c7d7df !important;
    }
    td:first-child {
      border-right: 1px solid #c7d7df !important;
    }
  }
}


.addItemsContainerBlock{
    margin-top:50px;
  }
  .createInwardAddDetailsBlock{
      color:#bbbbbb;
      font-size:15px;
      
  }
  .searchBlockContainer{
      border-radius:8px;
      outline:none;
      padding:10px 10px;
      float:right;
      border:none;
      background:#e2f2f7;
  }
  
  .addButtonBlock{
      background:#346785;
      color:white;
      padding:8px 48px;
      margin:10px 1px;
      border:none;
      border-radius:8px;
  }
  
  .inputBlockContainer{
      width:100%;
      border:none;
      padding:11px 0px 11px 5px;
      background:#e2f2f7;
      border-radius:8px;
      outline:none;
      &hover{
          border:none;
          outline:none;
      }
  }
  .deleteDisplayButton{
    //   border:none;
      background:#dfecf1;
      border-radius: 8px;
      padding:6px 10px;
      border:1px solid #007fad;
      
  }
  .checkboxBlock{
      margin-top: 2px;
  }
  .dropdownBlockContainer{
      width:100%;
      border:none;
      background:#e2f2f7;
      border-right:1px solid red;
      margin:0!important;
  }
  .dynamicSelectBlock .css-1s2u09g-control{
      border:none;
      background:#e2f2f7;
      outline:none;
      
      &hover{
          border:none;
      }
  }
  .dynamicSelectBlock{
  display:flex;
  align-items:center;
   
   margin:0!important;
  }
  
  .quantityDropdownBlock .selectAndTextfield {
      .tableDisplayBlock{padding: 0!important;}
    //   .customDropdown .css-1s2u09g-control, .customDropdown .css-1pahdxg-control{border: 1px solid #e2f2f7 ;margin-top: 0!important;border-radius: 10px 0 0 10px!important;border-right:1px solid #bbbbbb}
    //   .inputBlockContainer{border-radius: 0 10px 10px 0!important;}
    //   .css-319lph-ValueContainer {padding: 0px 2px !important;}
    //   .css-tlfecz-indicatorContainer {padding: 08px !important;}
    //   .css-6j8wv5-Input {
    //       margin: 0 !important;
    //       padding-bottom:0!important;
    //   }
     
    
  }
//   .gridContainer .gridComponent {padding-top:0px!important;}
//   .gridContainer .gridComponent table td{
//       padding:9px 10px 9px!important;
//   }
//       .selection-cell-header{
      
//           border-right: 1PX SOLID #c7d7df!important;
//       }
//       .gridContainer .gridComponent table th:nth-child(2) {
//         //  border: 1PX SOLID red!important;
//          border-radius: 10px 0 0 0;
//       }
//       .gridContainer .gridComponent table {
// font-size:14px!important;
//       }
  .tableRowDataContainer{
      display:flex;
      justify-content:center;
  }
  .editableDataTableContainer{
    border-right: 1PX SOLID #c7d7df!important;
  }

.OtherCharges{
    color: red;
    cursor: pointer;
    font-size: 14px;
}

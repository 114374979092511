.autoCompleteDropdownContainer {

  //   .MuiAutocomplete-endAdornment {
  //     -webkit-appearance: none !important;
  //     -moz-appearance: none !important;
  //     appearance: none !important; /* Remove default arrow */
  //     background-image: url(../images/dropdownArrow.png); /* Add custom arrow */
  //     background-repeat: no-repeat;
  //     background-position: calc(100% - 15px) calc(100% - 15px);
  //     background-size: 15px;
  //   }
  label {
    margin-bottom: 8px;
    font-size: 13px;
    color: #808080;
    font-family: "poppins-regular";
  }

  input {
    &::placeholder {
      color: #ccd5da;
      opacity: 1;
      /* Firefox */
      font-size: 16px;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #ccd5da;
      font-size: 16px;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #ccd5da;
      font-size: 16px;
    }
  }

  .css-17vbkzs-MuiFormControl-root-MuiTextField-root {
    border: 1px solid #ccd6db;
    padding: 5px 5px;
    border-radius: 10px;
    margin-top: 5px;
    width: auto;
    display: flex;
  }

  .css-ghsjzk-MuiInputBase-root-MuiInput-root:before,
  .css-ghsjzk-MuiInputBase-root-MuiInput-root:after {
    border: none;
  }

  .css-ghsjzk-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
    border: none;
  }

  .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    border: 1px solid #ccd6db;
    padding: 3.5px 6px;
    border-radius: 10px;
    margin-top: 5px;
    width: auto;
    display: flex;
    background: white;
  }

  .MuiAutocomplete-hasPopupIcon.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root,
  .MuiAutocomplete-hasClearIcon.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 !important;
    border: none;
  }

  // .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root{padding:n}
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none;
  }
}

#multiselectContainerReact {

  .chip {
    white-space: normal;
  }

  .searchWrapper {
    border: 1px solid #ccc;
    border-radius: 10px;
    min-height: 22px;
    padding: 5px;
    position: relative;
    background: #ffffff;
    line-height: 35px;
    margin-top: 5px;
  }
}

.autoCompleteDropdownContainer {
  label {
    margin-bottom: 10px;

    span {
      margin-left: 3px;
      color: red;
    }
  }

  .rmsc {
    .dropdown-container {
      position: relative;
      outline: 0;
      background-color: var(--rmsc-bg);
      border: 1px solid var(--rmsc-border);
      border-radius: 10px;
      /* height: 48px; */
      margin-top: 5px;
      padding: 2px;
    }

    .dropdown-content {
      position: absolute;
      z-index: 1;
      top: 100%;
      width: 100%;
      padding-top: 6px;
    }

    .dropdown-content .panel-content {
      overflow: hidden;
      border-radius: var(--rmsc-radius);
      background: var(--rmsc-bg);
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 0px 1px rgba(0, 0, 0, 0.1);
      margin: 0;
      color: #000;
    }

    .options {
      max-height: 260px;
      overflow-y: auto;
      margin: 0;
      padding-left: 0;
      border: 1px solid #000;
    }

    .options li {
      list-style: none;
      margin: 0 0 -7px 0;
    }

    .select-item {
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      outline-offset: -1px;
      outline-color: #000;
    }
  }
}

.autoCompleteDropdownContainerNew {
  label {
    margin-bottom: 10px;
    .mandotary {
      margin-left: 3px;
      color: red;
    }
  }

  .rmsc {
    .dropdown-container {
      position: relative;
      outline: 0;
      background-color: var(--rmsc-bg);
      border: 1px solid var(--rmsc-border);
      border-radius: 10px;
      /* height: 48px; */
      margin-top: 5px;
      padding: 2px;
    }

    .dropdown-content ul {
      span {
        color: black !important;
        font-size: 16px;
        font-weight: 200;
        margin-bottom: 4px;
      }
      input {
        background:black;
        border: none;
        margin-top: 5px;
      }
      li {
        list-style: none; 
      }
      .options{
        align-items: center;
      }
    }
    .dropdown-content .panel-content {
      overflow: hidden;
      border-radius: var(--rmsc-radius);
      background: var(--rmsc-bg);
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 0px 1px rgba(0, 0, 0, 0.1);
      margin: 0;
      color: #000;
    }

    .dropdown-content ul :hover {
      background: #0096fb;
      span {
        color: #fff !important;
        font-size: 16px;
        font-weight: 200;
        margin-bottom: 4px;
      }
      
    }
  }
}
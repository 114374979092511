.gridTableInfo{
    background:#e1eff7;
    width:100%;
    border-radius:4px;
    display: flex;
    justify-content: space-between;
    div{
      margin:0 5px;
    }
    
    .lightBlueButton{
      margin:0 5px!important;
    }
  }
  // .customizeViewContainer:before{
  //   .filterContainer .columnFilterContainer:before {
  //       content: " ";
  //       height: 0;
  //       position: absolute;
  //       width: 0;
  //       top: -20px;
  //       right: 40px;
  //       border: 10px solid transparent;
  //       border-bottom-color: #ffffff;
  //   }
  // }
  
  // .customizeViewContainer{
  //   background: #ffffff;
  //   position: absolute;
  //   padding: 20px;
  //   right: 5%;
  //   border-radius: 15px;
  //   top: 45px;
  //   width: 200px;
  //   height: 175px;
  //   box-shadow: 0px -3px 20px -6px darkslategrey;
  //   .customizeViewContent{
  //       display: block;
  //       overflow-y: auto;
  //       height: 175px;
  //           &::-webkit-scrollbar {
  //           width: 5px;
  //           }
  //           /* Handle */
  //           &::-webkit-scrollbar-thumb {
  //           background: #007fad;
  //           border-radius: 5px;
  //           }
  //           /* Handle on hover */
  //           &::-webkit-scrollbar-thumb:hover {
  //           background: #bdbdbd;
  //           }
  //   }
  //   div{
  //       display: flex;
  //       align-items: center;
  //       font-size: 13px;
  //       line-height: 35px;
  //       color: #8f9592;
  //   }
  //   input[type="checkbox"]{
  //       margin-right:10px;
  //   }
  //   input[type="checkbox"] {
  //       -webkit-appearance: none;
  //       background-color: transparent;
  //       border: 2px solid #acc4d1;
  //       padding: 9px;
  //       border-radius: 5px;
  //       display: inline-block;
  //       position: relative;
  //     }
  //     input[type="checkbox"]:checked {
  //       background-color: #004b73;
  //       border: 2px solid #004b73;
  //       color: #ffffff;
  //     }
  //     input[type="checkbox"]:checked:before {
  //       position: absolute;
  //       left: -3px;
  //       top: 38%;
  //       height: 45%;
  //       width: 2px;
  //       background-color: #ffffff;
  //       content: "";
  //       transform: translateX(10px) rotate(-45deg);
  //       transform-origin: left bottom;
  //     }
  //     input[type="checkbox"]:checked:after {
  //       position: absolute;
  //       left: -2px;
  //       bottom: 5px;
  //       height: 2px;
  //       width: 65%;
  //       background-color: #ffffff;
  //       content: "";
  //       transform: translateX(10px) rotate(-45deg);
  //       transform-origin: left bottom;
  //     }
  // }
  .customizeViewTable{
    table {
        th:first-child{border-right:1px solid #c7d7df!important;}
        td:first-child{border-right:1px solid #c7d7df!important;}
    }
  }
  
  ///////////////////////////////////////////////////////////////////////////customizeView/////////
  
  .customizeViewTable{
  .searchBarBlock{
  border:none;
  background:#c9e2ec;
  border-radius: 8px;
  color:#007fad;
  
  float:right;
  }
  .sr-only{
  display:none;
  }
  }
  .customizeViewTable
  .searchBarBlock {
  
  display: flex;
  position: relative;
  height: 20px;
  float: right;
  padding: 0;
  
  
  
  input[type="text"] {
  height: 35px;
  font-size: 18px;
  display: inline-block;
  font-family: "Lato";
  font-weight: 30;
  border: none;
  outline: none;
  color: #555;
  padding: 3px;
  padding-right: 20px;
  width: 30px;
  position: absolute;
  top: 63px;
  right: 0;
  z-index: 3;
  transition: width .4s cubic-bezier(0.000, 0.795, 0.000, 1.000);
  cursor: pointer;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADNQTFRFU1NT9fX1lJSUXl5e1dXVfn5+c3Nz6urqv7+/tLS0iYmJqampn5+fysrK39/faWlp////Vi4ZywAAABF0Uk5T/////////////////////wAlrZliAAABLklEQVR42rSWWRbDIAhFHeOUtN3/ags1zaA4cHrKZ8JFRHwoXkwTvwGP1Qo0bYObAPwiLmbNAHBWFBZlD9j0JxflDViIObNHG/Do8PRHTJk0TezAhv7qloK0JJEBh+F8+U/hopIELOWfiZUCDOZD1RADOQKA75oq4cvVkcT+OdHnqqpQCITWAjnWVgGQUWz12lJuGwGoaWgBKzRVBcCypgUkOAoWgBX/L0CmxN40u6xwcIJ1cOzWYDffp3axsQOyvdkXiH9FKRFwPRHYZUaXMgPLeiW7QhbDRciyLXJaKheCuLbiVoqx1DVRyH26yb0hsuoOFEPsoz+BVE0MRlZNjGZcRQyHYkmMp2hBTIzdkzCTc/pLqOnBrk7/yZdAOq/q5NPBH1f7x7fGP4C3AAMAQrhzX9zhcGsAAAAASUVORK5CYII=) center center no-repeat;
  }
  
  input[type="text"]:focus {
  width: 180px;
  z-index: 1;
  border-bottom: 1px solid #BBB;
  cursor: text;
  }
  input[type="submit"] {
  height: 67px;
  width: 51px;
  display: inline-block;
  float: right;
   background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADNQTFRFU1NT9fX1lJSUXl5e1dXVfn5+c3Nz6urqv7+/tLS0iYmJqampn5+fysrK39/faWlp////Vi4ZywAAABF0Uk5T/////////////////////wAlrZliAAABLklEQVR42rSWWRbDIAhFHeOUtN3/ags1zaA4cHrKZ8JFRHwoXkwTvwGP1Qo0bYObAPwiLmbNAHBWFBZlD9j0JxflDViIObNHG/Do8PRHTJk0TezAhv7qloK0JJEBh+F8+U/hopIELOWfiZUCDOZD1RADOQKA75oq4cvVkcT+OdHnqqpQCITWAjnWVgGQUWz12lJuGwGoaWgBKzRVBcCypgUkOAoWgBX/L0CmxN40u6xwcIJ1cOzWYDffp3axsQOyvdkXiH9FKRFwPRHYZUaXMgPLeiW7QhbDRciyLXJaKheCuLbiVoqx1DVRyH26yb0hsuoOFEPsoz+BVE0MRlZNjGZcRQyHYkmMp2hBTIzdkzCTc/pLqOnBrk7/yZdAOq/q5NPBH1f7x7fGP4C3AAMAQrhzX9zhcGsAAAAASUVORK5CYII=) center center no-repeat;
  text-indent: -10000px;
  background-size:30px;
  border: none;
  position: absolute;
  top: 54px;
  right: 0;
  z-index: 2;
  cursor: pointer;
  opacity: 0.4;
  cursor: pointer;
  transition: opacity .4s ease;
  }
  
  input[type="submit"]:hover {
  opacity: 0.8;
  }
  }
  .EntriesExitBlockContainer{
  color:#003f66;
  font-size:40px;
  display:block;
  line-height:40px;
  }
  .gridComponent .css-mhc70k-MuiGrid-root  {
  align-items:center!important;
  margin-left:0px!important;
  margin-right: 0px!important;
  width:100%;
  margin-top:15px;
  }
  .EntriesExitBlock{
  font-size:14px;
  color:#9bb9cb;
  display:flex;
  justify-content:center!important;
  }
  .searchIconBlock{
  width:40px;
  }
  .customizeViewTable .css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-top: 0px!important;
  }
  .gridViewIcon{
    border-radius:50%;
    width: 10px;
    background: #005c83;
    padding:10px;
    height:10px;
  }
  
  .headerButtonBlock{
    background:#ebfaff;
  padding-bottom:10px;
  border-radius:4px;
  margin-top:10px!important;
  width:100%!important;
   
  }
  .inwardEntryContainer .gridContainer .gridComponent table td:first-child {
    border-right: 1PX SOLID #c7d7df!important;
  }
  .gridTableEntryBlock{
    margin-bottom: 40px;
    width:100%;
    border-radius:4px;
    display: flex;
    justify-content: space-between;
    div{
      margin:0 5px;
    }
    .lightBlueButton{
      margin:0 5px!important;
    }
    
  }
  .inwardEntryContainer{
    .filterDropdownArrow {
      right: -08px !important;
    }
    .css-1iwtne7-MuiTypography-root-MuiLink-root {
      margin: 0px !important;
    }
    .dashBoardButton {
      padding:7px 30px!important;
    }
   
    .filterContainer {
      right: -40px !important;
    }
    .gridContainer .gridComponent table th:first-child {
      border-right:1px solid red;
    }
   
  }
.toggleContainer .toggleOption:nth-child(2) {
  background: rgb(220, 220, 220);
  background: linear-gradient(
    90deg,
    rgba(220, 220, 220, 1) 0.5%,
    rgba(255, 255, 255, 0) 0.5%,
    rgba(255, 255, 255, 0) 99.5%,
    rgba(220, 220, 220, 1) 99.5%
  );
}

.toggleContainer .toggle.on-class {
  background: #23aafa;
  border-radius: 10px 0px 0px 10px !important;
}

.toggleContainer .toggle.pending-class {
  background: #f5980b;
}
.toggleContainer .toggle.hold-class {
  background: #004b73;
}
.toggleContainer .toggle.off-class {
  background: #6052ff;
  border-radius: 0px 10px 10px 0px !important;
}
.toggleContainer {
  border-radius: 10px 10px !important;
  box-shadow: 0px 0px 4px 0px #bbbbbb !important;
}
.toggleContainer .toggleOption {
  height: 62px !important;
  line-height: 62px !important;
}
.iconToggleComponent {
  background-color: #cce5ef;
  padding: 5px 10px;
  border-radius: 10px !important;
  .MuiToggleButton-root {
    border: none;
    padding: 5px 10px;
    background: none !important;
  }
  button:first-child{
    margin-right:15px;
    margin-left:5px;
  }
  .Mui-selected {
    background-color: #fff !important;
    border-radius: 5px !important;
  }
}
.btnToggleforIndent {
  .MuiToggleButton-root {
    background: none !important;
    margin: 5px !important;
    border: 2px solid #007fad !important;
    border-radius: 25px !important;
    color: #007fad !important;
    font-size: 13px !important;
    text-transform: capitalize;
    padding: 10px 20px;
  }
  .Mui-selected {
    background-color: #007fad !important;
    color: #ffffff !important;
    font-size: 13px !important;
  }
}

.EmployeeDatabaseContainer {
  box-shadow: 0px 0px 4px 0px #bbbbbb;
  border-radius: 8px;
  width: 18%;
  margin: 1%;
  float: left;
  height: 215px;

  .userManagementContainer {
    height: 185px;
    .closeIcon {
      position: absolute;
      border: none;
      top: 10px;
      right: 10px;
      background: none;
    }
    .closeIcon:hover {
      opacity: 0.3;
      cursor: pointer;
    }
    .userProfilePic {
      padding-top: 30px;
      justify-content: center;
    }
    .container-userProfilePic {
      border-radius: 120px;
      align-items: center;
    }
    .profilePic {
      width: 70px;
      border-radius: 50%;
      border: 2px solid #23aafa;
      padding: 2px;
    }
    h1 {
      font-size: 14px;
      margin-bottom: 0px;
      margin-top: 0px;
    }
    .userProfileInfo {
      justify-content: center;
      display: flex;
      align-items: center;
    }
    .profileContainerBlock {
      padding: 20px 10px 0px 10px;
      text-align: center;
      height: 135px;
    }
    .InstructionPointer {
      justify-content: center;
      background: #a0de00;
      border-radius: 50%;
      width: 11px;
      height: 11px;
    }
    .InstructionPointer-orange {
      justify-content: center;
      background: #f5980b;
      border-radius: 50%;
      width: 11px;
      height: 11px;
    }
    .InstructionPointer-purple {
      justify-content: center;
      background: #796fff;
      border-radius: 50%;
      width: 11px;
      height: 11px;
    }
    .InstructionPointer-pink {
      justify-content: center;
      background: #fa234a;
      border-radius: 50%;
      width: 11px;
      height: 11px;
    }
    .employeeId {
      text-align: center;
      color: #4d4d4d;
      margin: 4px 0px;
      font-size: 13px;
      height: 135px;
    }
    .userProfileInfo .workingHours {
      text-align: center;
      color: #666666;
      margin: 0px 12px;
      font-size: 11px;
    }
    .cardViewFooter {
      background: #ebfaff;
      padding: 10px;
      text-align: center;
      border-radius: 0 0 10px 10px;
      margin-top: 10px;
      height: 40px;
      line-height: 20px;
      .productDesigner {
        align-items: center;
        font-size: 14px;
        color: #757d7f;
      }
    }
  }
}
.cardViewWithPagination {
  display: flex;
  flex-direction: column;
  .cardViewPagination {
    display: flex;
    justify-content: end;
    .active {
      padding: 1px 10px;
      background: #e2e9ed;
      margin: 0 5px;
      border-radius: 5px;
      cursor: pointer !important;
    }
    li {
      margin: 0 10px;
      a {
        color: #a5bdcc;
        font-size: 13px;
        cursor: pointer !important;
      }
    }
  }
}

.welcomeLetterContainerBlock{
    padding:20px 60px;
    box-shadow:0px 0px 5px -3px;
    border-radius:8px;
 h1{
    font-size:18px;
    color:#01314a;
   margin:30px 0px 0px 0px;
 }
 p{
    color:#7d7d7d;
    font-size:12px;
 }
 .welcomeLetterBlock{
    margin:0px;
 }
 .welcomeAboard{
    margin:40px  0px 20px 0px;
 }
 .greetings{
    margin:20px  0px 30px 0px;
 }
 .companyGreetings{
    margin:30px 0px;
 }
 .mailDetails{
    margin:0px 0px 5px 0px;
 }
 .password{
    margin:0px 0px 30px 0px;
 }
 .location{
    margin:0px;
 }

}
.tabsNavigationBlock {
  .MuiButtonBase-root-MuiTab-root {
    min-width: auto !important;
    padding: 0 !important;
    margin-right: 25px;
    font-family: "poppins-regular";
    text-transform: none;
    color: #cce5ef;
  }
  .MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #004b73;
    font-family: "poppins_bold";
    text-transform: none;
  }
  .MuiTabs-indicator {
    background-color: #004b73;
    border-radius: 10px;
    height: 4px;
  }
}
.toggle-alignment {
  position: absolute;
  right: 15px;
}
// .tabNavBlock {
//   .MuiTabsList-root {
//     height: 45px;
//     align-items: flex-end;
//   }
// }
.tabNavigationLinks {
  .MuiTabsList-root {
    height:39px;
  }
}

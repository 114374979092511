.previewBlock {
  // box-shadow: 0 0 2px 2px;
  // padding: 50px 20px;
  padding: 0 20px;
  font-size: 14px;
  .previewTable {
    box-shadow: 0 0 25px 0px #dcdcdc;
    padding: 10px;
    border: 2px solid #ffffff !important;
    img{
      width:200px;
    }
    .previewTableHeader {
      margin-bottom: 20px;
      td {
        border: none;
      }
    }
    .previewAddressTable {
      margin-bottom: 20px;
      td {
        border: none;
      }
    }
    table {
      width: 100%;
      td {
        border: 0.4px solid #000000;
      }
    }
    .mainTable {
      width: 100%;
      margin: 20px 0;
    }
    .mainTable {
      th {
        background: #00abdc;
        color: #ffffff;
        font-weight: normal !important;
        font-size: 13px;
        padding: 5px;
        text-align: left;
      }
      td {
        border: none !important;
      }
      tr:nth-child(even) {
        background: #f7f7f7;
      }
      tr:nth-child(odd) {
        background: #fff;
      }
    }
  }
  table {
    border-collapse: collapse;
    margin: 0 auto;
  }
  td {
    border: 0.5px solid #000000;
    padding: 5px;
    table{
      td{
        border:none!important;
        padding:0!important;
        width:50%;
      }
    }
  }
  button {
    margin-left: 10px;
    .MuiSvgIcon-root {
      margin-left: 5px;
      width:20px;
      height: 20px;
    }
  }
}
.previewFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  position: sticky;
  padding: 10px;
  bottom: 0;
  border: 1px solid grey;
  box-shadow: 0px -11px 22px -10px #b7b7b7;
  height: 75px;
  button {
    margin: 0 5px;
  }
}

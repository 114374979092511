.offerLetterContainerBlock{
    padding:20px 60px;
    box-shadow:0px 0px 5px -3px;
    border-radius:8px;
    margin-bottom:10px;
    h1{
        text-align:left;
        font-size:18px;
        color:#01314a;
        margin:20px 0px 0px 0px;
    }
   .offerLetterBlock{
    margin:0px;
    color:#7a7a7a;
    font-size:14px;
    margin-bottom:20px;
   }
    .employeeDetailsBlock{
        display:flex;
        justify-content:space-between;
       margin:40px 0px;
        .employeeBlock{
             p{
            margin:0px;
            font-size:14px;
        }
        h3{
            margin:0px;
            color:#007fad;
            font-size:15px;
        }
        h2{
            margin:0px;
            color:#007fad;
            font-size:14px;
        }
        span{
            margin-top:10px;
            font-size:14px;
            display:flex;
            flex-direction:column;

        }
    }
    }
    .gridContainer .gridComponent table {
      width: 100%;
      border-collapse: collapse;
      font-size: 14px;
  }
  .gridContainer .gridComponent table td {
    padding: 9px 10px 9px !important;
}
   
    table {
        tr {
        border-bottom: 1px solid #707070;
        border-top: 1px solid #707070;
        &:nth-child(odd) {
          background: white!important;
        }
      }
      td{
        color: black!important;
        border-right: 1px solid #707070!important;
        border-left: 1px solid #707070;
        &:last-child {
          font-size:13px;
          width:25%;
        }
        &:first-child { 
          width: 50%;
          font-size:13px
        }
      }
      th{
        border-radius:0px!important;
        background:#007fad;
        border-left: 1px solid #707070;
        border-right: 1px solid #707070!important;
      }
    }
    .gridContainer .gridComponent table th:first-child {
      border-right:none!important;
    }
    .ctcBlock{
      font-weight:bold;
    }
     
}
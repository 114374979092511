.radioContainer{
  display:flex;
  align-items:center;
  margin-right:20px;
  label{
    color:#336f8f;
    margin-left: 5px;
    font-size: 15px;
    margin-bottom: 0;
  }
}
.radioButtonBlock {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px #d9d9d9;
  padding: 0 0 10px 0;
  background: #ffffff;
  .radioBlock{padding: 0 10px;}
  img {
    height: 400px;
    border-radius: 10px 10px 0 0;
  }
}

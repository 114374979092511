.otpContainer {
  width: 40%;
  margin: 0 auto;
  h3{
      margin-bottom:5px;margin-left:10px;color:#013148;
  }
  .otpContent {
    display: flex;
    justify-content: center;
    input {
      padding: 10px;
      height: 50px;
      width: 50px;
      border-radius: 10px;
      margin: 5px;
      border: 1px solid #b3c2c9;
      text-align: center;
      font-size: 20px;
      color: #013148;
      font-family: "poppins_bold";
    }
  }
  a {
    float: right;
    border: none;
    background: none;
    display: block;
    font-size: 13px;
    color: #013148;margin-right: 10px;
  }
}

.VideoGifContainer {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px #d9d9d9;
  margin: 0 10px;
  background: #ffffff;
  // border-radius: 10px;
  img {
    border-radius: 10px;
    width:100%
  }
  
}
.VideoGifBlankContainer {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px #d9d9d9;
  margin: 0 10px;
  background: #ffffff;
  // border-radius: 10px;
  .gifBlankImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .blankVideoImage {
    width: 50px;
    margin: 100px;
  }
}

.indentHeaderBlock {
  display: flex;
  justify-content: space-between;
}
.createIndentContainerBlock {
  display: flex;
  align-items: center;
}
.backIconPic {
  color: #346785;
  font-size: 28px;
  margin-right: 5px;
}
.createIndentBlock {
  color: #346785;
  text-align: left;
  font-size: 18px;
  margin: 0px;
}
.gridAddItemsBlock {
  color: #346785;
  text-align: left;
  font-size: 16px;
}
.expandBlock {
  width: 40px;
}

.indentContainerBlock .MuiStepLabel-label {
  margin: 0px !important;
}
.indentContainerBlock .MuiStepConnector-root {
  top: 20px !important;
}
.indentContainerBlock .MuiStepLabel-root {
  margin-top: 0px !important;
  color: #cbcbcb;
}
.indentContainerBlock .MuiStepper-root MuiStepper-horizontal {
  align-items: center;
}
.indentContainerBlock {
  display: flex;
  margin: 0;
}
.indentContainerNavBlock {
  width: 200px;
  margin: 0px;
}
.indentContainerMainBlock {
  width: 100%;
  .amountTable {
    td {
      padding: 5px;
    }
    .noBorder {
      border: none;
    }
  }
  .personalDetailsUpload {
    .remarksBlock {
      button {
        display: flex;
      }
    }
    .filesUploadedBlock {
      min-height: 175px;
      .documentUpload {
        margin-bottom: 15px;
      }
    }
    .ducumentUploadBlock {
      .documentUploadContent {
        label {
          margin-bottom: 8px;
          font-size: 13px;
          color: #808080;
          font-family: "poppins-regular";
        }
        .documentUpload {
          display: flex;
          justify-content: center;
          align-items: center;
          width: auto;
          // margin-top: 25px;
          height: 35px;
        }
        .deleteButton {
          width: 30px;
          height: 30px;
          padding: 0;
        }
        .uploadedInfo {
          line-height: normal;
          margin-top: 0px;
          height: 35px;
          display: flex;
          justify-content: space-between;
          box-shadow: 0 0 10px -7px;
          .uplodedFileName {
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
.logsContainer{
  padding:0 30px;
  .logsContent{
    font-size: 13px;
    margin-bottom: 5px;
    list-style-type: disc;
  }
}
.notifictionPicBlock {
  width: 20px;
}
.databasePicBlock {
  width: 20px;
  margin-top: 5px;
  margin-right: 9px;
}
.notificationDbIconBlock {
  display: flex;
  align-items: center;
}
.notificationPicBlockBackground {
  background: #dfecf1;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  height: 40px;
  width: 42px;
  align-items: center;
  margin-right: 20px;
}
.iconsBlock img {
  height: 35px;
}
.iconsBlock {
  margin: 5px 0px;
  .MuiStepper-alternativeLabel {
    width: 35% !important;
    margin: 0px auto !important;
    font-size: 13px;
  }
}
.MuiStepConnector-line {
  color: #cbcbcb !important;
}

.incrementDecrementContainer {
  .probitionBlock {
    display: block;
    color: #808080;
    font-size: 13px;
  }

  .textfiledContainer {
    padding: 12px 13px;
    border-radius: 8px 0px 0px 8px;
    border-right: none;
    border: 1px solid #ccd6db;
    font-size: 18px;
    width: 80%;
  }

  .incrementBlock {
    border-radius: 0px 8px 8px 0px;
    border: 1px solid #ccd6db;
    background: white;
    // width:10%;

    .incrementIcon {
      width: 18px;
      position: relative;
      top: 4px;
      cursor: pointer;
    }

    svg {
      fill: #004b73;
    }
  }

  .decrementBlock {
    border-radius: 0px 0px 0px;
    border-right: none;
    border: 1px solid #ccd6db;
    background: white;

    // width:10%;
    .decrementIcon {
      width: 20px;
      cursor: pointer;
    }

    svg {
      fill: #004b73;
    }
  }

  .incDecContent {
    display: flex;
  }
}

.verificationContainer {
  border: 1px solid #93bcc9;
  border-radius: 8px;
  display: flex;
  padding: 0px;
  overflow: hidden;
  background: #fbfbfb;
  justify-content: space-evenly;

  td {
    .inputBlockContainer {
      min-width: 130px;
    }
  }

  .inputBlockContainer {
    // width: 50%;
    // border: none;
    padding: 11px 0px 11px 5px;
    background: #e2f2f7;
    // border: 1px 0px 0px 1px solid #93bcc9 !important;
    border: none;
    border-radius: 0px;
    outline: none;


    &hover {
      border: none;
      outline: none;
    }
  }

  .verifiedBlock {
    height: 43px;
    border-radius: 0px;
    border: none;
    border-left: 1px solid #93bcc9;
    display: flex;
    align-items: center;
    outline: none;
    padding-left: 22px;
    // background: #e2f2f7;
    &hover {
      border: none;
      outline: none;
    }

    color: #73a2bc !important;
    font-weight: 100;
    font-size: 15px !important;
  }

  // inputBlockContainer
  // border: none !important;
  // border-radius: 0px;


  // border: 1px solid #93bcc9;
  //   border-radius: 8px;
  //   display: flex;
  //   padding: 0px;
  //   overflow: hidden;

  //----verificationContainer .inputBlockContainer    
  // width: 50%;
  // padding: 11px 0px 11px 5px;
  // background: #e2f2f7;
  // border: 1px 0px 0px 1px solid #93bcc9 !important;
  // border-radius: 0px;
  // outline: none;
  // border: none !important;
  // border-radius: 0px;


  //verifiedBlock
  // 1px solid #93bcc9

  //----incDecContent
  //    border: 1px solid #93bcc9;
  // border-radius: 8px;
  // display: flex;
  // padding: 0px;
  // overflow: hidden;
  ///
}
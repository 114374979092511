.checkboxBlock{
  display: flex;
  align-items: center;
  font-size: 14px;
  color:#336f8f;
  .iconSize {
    width: 40px;
    height: 40px;
    margin-right:10px;
  }

.checkbox {
  -webkit-appearance: none;
  background-color: #f6fdff;
  border: 2px solid #acc4d1;
  padding: 9px;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  margin-right:15px;
}
.checkbox:checked {
  background-color: #004b73;
  border: 2px solid #004b73;
  color: #ffffff;
}
.checkbox:checked:before {
  position: absolute;
  left: -3px;
  top: 38%;
  height: 45%;
  width: 2px;
  background-color: #ffffff;
  content: "";
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}
.checkbox:checked:after {
  position: absolute;
  left: -2px;
  bottom: 5px;
  height: 2px;
  width: 75%;
  background-color: #ffffff;
  content: "";
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}}

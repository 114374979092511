.filterComponent {
  .MuiPaper-root-MuiAccordion-root {
    border: none !important;
  }
  .MuiAccordionDetails-root {
    border: none !important;
    background-color: #fafafa;
    font-weight: normal !important;
  }
  .MuiSvgIcon-root {
    width: 10px;
    height: 10px;
    color: #7a92a0;
  }
  .MuiTypography-root {
    color: #01314a;
    font-size: 13px;
    //font-weight: bold;
  }
  .filterHeading {
    font-weight: bold;
  }
  .MuiButtonBase-root {
    padding: 0 !important;
  }
}
.hrmsFilter {
  .consoleTextFieldBlock {
    margin: 0;
  }
}
.procurementFilter {
  .consoleTextFieldBlock {
    margin: 0 !important;
    width: 100%;
    .fromDatePicker {
      margin: 0 !important;
    }
  }
}
.prResponseFilter {
  .consoleTextFieldBlock {
    margin-top: 10px!important;
  }
}

.newDashboardDesign {
  align-items: center !important;
  // .css-1kuc5br-MuiGrid-root > .MuiGrid-item {
  //   padding-left: 0px !important;
  // }
  .createIndentBlock {
    color: #004b73 !important;
  }
  .DateContainerBlock {
    color: #004b73;
    font-size: 12px;
  }
  .dashboardCardCompanyIconBlock {
    img {
      height: 40px;
      border-radius: 50%;
      padding: 10px 10px;
    }
  }
  .dashboardCardUserIconBlock {
    img {
      height: 40px;
      border-radius: 50%;
      padding: 10px 10px;
    }
  }
  .dashboardCardContainer p {
    font-size: 11px;
    color: white;
    font-weight: none !important;
    margin: 0;
  }
  .dashboardCardContainer h2 {
    font-size: 25px;
    color: white;
    margin: 0;
  }

  .dashboardCompanyContentBlock {
    background: #796fff;
    border-radius: 8px;
    margin: 10px 0px;

    .css-46bh2p-MuiCardContent-root {
      padding: 15px 0px;
    }
  }
  .dashboardUserContentBlock {
    background: #23aafa;
    border-radius: 8px;
    margin: 10px 0px;
    .css-46bh2p-MuiCardContent-root {
      padding: 15px 0px;
    }
  }
  .dashboardInternsCompanyContent {
    background: #f5980b;
    border-radius: 8px;
    margin: 10px 0px;
    .css-46bh2p-MuiCardContent-root {
      padding: 15px 0px;
    }
  }
  .dashboardCompanyDailyContent {
    background: #fa234a;
    border-radius: 8px;
    margin: 10px 0px;
    .css-46bh2p-MuiCardContent-root {
      padding: 15px 0px;
    }
  }
  .employeeList {
    display: flex;
    align-items: center;
  }
  // .hrManagementContainer{
  //   justify-content:space-between;
  //   align-items:center;
  //   width:100%;
  //   margin-left:0px!important;
  //   margin-bottom:15px;
  //   .consoleFormButtonBlock {
  //       padding:0px!important;

  //   }
  //   .css-mhc70k-MuiGrid-root>.MuiGrid-item {
  //       padding-left:0px!important;
  //   }
  //   .css-mhc70k-MuiGrid-root {
  //       margin-left:0px!important;
  //   }
  //   .consoleFormButtonBlock .saveButton {
  //       margin-right:0px!important;
  //   }
  // }

  /*Dashboard Employee database*/
  .DashboardEmployeeDatabase {
    box-shadow: 0px 0px 04px 0px #bbbbbb;
    border-radius: 8px;
    padding-bottom: 6px;
    background: white;
    margin-top: 5px;
    margin-right: 15px;
  }
  .employeesInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dde7ee;
    padding: 10px 10px;
  }
  .employeeNames {
    margin: 0px;
    color: #1a455b;
    font-size: 15px;
  }
  .employeeView {
    margin: 0px;
    color: #1a455b;
    font-size: 10px;
    border-bottom: 1px solid #1a455b;
    font-weight: bold;
    cursor: pointer;
  }
  .dashboardEmployeeContent {
    background: #f2f5fa;
    border-radius: 8px;
    //  width:350px;
  }

  .dashboardEmployeeBlock h1 {
    font-size: 13px;
    color: #44677a;
    margin: 0px;
  }
  .dashboardEmployeeBlock p {
    font-size: 11px;
    color: #44677a;
    margin: 0px;
  }
  .dashboardEmployeeContainer {
    padding: 0 10px;
    img {
      border-radius: 50%;
      height: 40px;
    }
  }
  .employeeProfile {
    margin-left: auto;
    margin-right: 0px;
  }
  .dashboardEmployeeBlock {
    margin: 10px 10px;
  }
  .donutSummary-innertext-value {
    display: none;
  }

  /*Upcoming Events*/
  .eventContainer {
    background: white;
    border-radius: 8px;
    margin-top: 5px;
    box-shadow: 0 0 4px 0px #bbbbbb;
  }

  .dashboardEventContainer {
    background: #f1f5fa;
    padding: 1px 0px;
    margin: 0px 10px;
    border-radius: 10px;
  }
  .eventsTableBlock {
    width: 100% !important;
    margin-left: 0px !important;
    margin-top: 0px !important;
    .MuiGrid-item {
      padding-left: 0px !important;
      padding-top: 0px !important;
    }
  }
  .dashboardEventContent {
    border-left: 4px solid #796fff;
  }
  .dashboardEventContentBlock {
    border-left: 4px solid #fa234a;
  }
  .dashboardEventBlock {
    h3 {
      font-size: 9px;
      margin: 0;
      opacity: 0.6;
    }
    h1 {
      font-size: 13px;
      margin: 0;
      color: #44677a;
    }
    p {
      font-size: 11px;
      margin: 0;
      color: #44677a;
    }
  }
  .eventProfile {
    img {
      border-radius: 50%;
      height: 20px;
    }
  }
  .eventProfile {
    margin-left: auto;
    margin-right: 0px;
    margin-bottom: 20px;
  }
  /**job application summary**/
  .jobApplicationSummaryBlock {
    background: white;
    margin-top: 14px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px #bbbbbb;

    .jobApplication {
      padding: 10px 0px 10px 10px;
      color: #1a455b;
      font-size: 15px;
      margin: 0;
    }
    .applicationInfo {
      // border-bottom: 1px solid #dde7ee;
      h3 {
        margin: 0;
      }
    }
  }
  .jobApplicatonSummaryContainer {
    // border: 1px solid red;
    margin: 0;
    svg {
      display: flex;
      align-items: center;
    }
  }
  .container {
    width: 385px;
    height: 165px;
    margin: 0px 10px;
    padding-top: 10px;
  }
  .mainContainer {
    display: flex;
    align-items: center;
  }
  /**navigation items**/
  .subContainer {
    // background:#004b73;
    height: 530px;
    margin-right: 10px;
  }
  .companyProfileContainer {
    border-bottom: 1px solid black;
  }
  .companyLogo {
    width: 120px;
    align-items: left;
    margin-top: 10px;
    margin-left: 5px;
  }
  .companyDetails {
    display: flex;
    align-items: center;
    background: #01314a;
    color: white;
    border-radius: 8px;
    margin: 10px 40px;
    justify-content: space-between;
    padding: 0px 10px;
  }
  .css-1kuc5br-MuiGrid-root {
    padding: 14px 0px;
  }
  .css-opxrfb-MuiGrid-root > .MuiGrid-item {
    padding: 0px !important;
  }
}
.listOfEmployeeBlock {
  align-items: center;
  display: flex;
  .greenBorderButton {
    background-color: #ffffff !important;
    border-radius: 10px !important;
    color: #94cd00 !important;
    font-size: 13px !important;
    border: 2px solid #94cd00 !important;
    margin-right: 10px;
  }

  .greenButton {
    background-color: #94cd00 !important;
    color: #ffffff !important;
    font-size: 13px !important;
    border: 1px solid transparent !important;
  }
}

/********HRMS Dashboard Layout**********/
.hrmsEmployeeDashboardSection {
  /***************************Leftside container*************************/
  .employeeDashboardLiftSideContainer {
    /**employeeCategorySection**/
    .employeeCategorySection {
      h3 {
        margin: 0px;
        color: #01314a;
      }
      padding: 18px;
      border-radius: 8px;
      box-shadow: 0px 0px 4px 0px #bbbbbb;
      .MuiCardContent-root {
        max-height: 80px;
        min-height: 80px;
        padding: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      .employeeDashboard {
        text-align: center;
        border-radius: 8px;
        padding: 8px;

        span {
          font-size: 24px;
          color: #004b73;
          font-weight: bold;
        }

        p {
          font-size: 12px;
          color: #004b73;
          font-weight: 500;
          margin: 5px;
        }
      }

      .dashBoardTotalEmployee {
        background: #a0de00;
        box-shadow: none;
      }

      .employeeWithESI {
        background: #dcebf2;
        box-shadow: none;
      }

      .employeeWithOnrollandContract {
        background: #e0f4ab;
        box-shadow: none;
      }

      .users {
        background: #eaeaea;
        box-shadow: none;
      }
    }

    /**on-Roll employee summary**/

    .jobApplicationSummaryBlock {
      background: white;
      margin-top: 0px;
      border-radius: 8px;
      padding: 18px;
      box-shadow: 0px 0px 4px 0px #bbbbbb;
      .applicationInfo {
        // border-bottom: 1px solid #dde7ee;
        h3 {
          margin: 0;
          color: #01314a;
        }
      }
      ul.legend {
        width: 225px;
        height: 220px;
        padding: 10px 0;
        overflow-y: auto;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        //justify-content: center;
        li {
          display: flex;
          align-items: center;
          div {
            &:first-child {
              margin-right: 10px;
            }
            &:last-child {
              font-size: 13px;
            }
          }
        }
        /* width */
        &::-webkit-scrollbar {
          width: 6px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #a8afb2;
          border-radius: 5px;
          height: 5px !important;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #92989b;
        }
      }
    }
    .doughnutContainer {
      position: relative;
      .doughnutOne {
        .totalAmount {
          position: absolute;
          top: 95px;
          left: 70px;
          font-size: 25px;
          font-weight: 600;
          width: 125px;
          text-align: center;
        }
        .donutchart {
          overflow: hidden;
        }
        .doughnutChartNiranjan-arcs {
          height: 200px;
        }
        .donutchart-arcs-path,
        .donutchart-legend-item,
        .donutchart-legend-item-label {
          cursor: pointer;
        }
        .donutchart-arcs {
          transform: matrix(0.8, 0, 0, 0.8, 0, 0);
        }
        .donutchart-legend {
          display: none;
        }
        .donutchart-innertext-label {
          font-size: 12px;
          transform: translate(-7%, 10%);
          font-weight: 500;
          fill: #004b73;
        }
        .donutchart-innertext-value {
          font-size: 25px;
          font-weight: bold;
          transform: translateY(-135px);
          display: none;
        }
        .donutchart-arcs-path,
        .donutchart-legend-item {
          fill-opacity: 0;
          animation-timing-function: ease-in-out;
          animation-fill-mode: forwards;
          animation-name: DrawLine, FillIn;
          animation-delay: 0.5s, 2s;
          animation-duration: 3s, 1s;
          stroke-dasharray: 1500;
          stroke-dashoffset: 1500;
        }
        @keyframes DrawLine {
          to {
            stroke-dashoffset: 0;
          }
        }
        @keyframes FillIn {
          from {
            fill-opacity: 0;
          }
          to {
            fill-opacity: 1;
          }
        }
      }
    }
    .jobApplication {
      padding: 10px 0px 10px 10px;
      color: #1a455b;
      font-size: 15px;
      margin: 0;
    }
    .jobApplicatonSummaryContainer {
      margin: 0;

      svg {
        display: flex;
        align-items: center;
      }
    }

    .container {
      // width: 385px;
      height: 250px;
      padding: 10px;
    }

    .mainContainer {
      display: flex;
      align-items: center;
    }
  }

  /***************************right side container*************************/
  .employeeDashboardRightSideContainer {
    /**********************monthly Attandance in HRMS dashboard***********************/
    .monthlyAttendanceAverageBlock {
      padding: 18px;
      border-radius: 8px;
      box-shadow: 0px 0px 4px 0px #bbbbbb;
      .monthAverageAndDropdown {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        h3 {
          margin: 0px;
          color: #01314a;
        }
        .attendanceThisMonth {
          justify-content: space-between;
          align-items: center;
          display: flex;
          padding: 8px;
          border-radius: 8px;
          background: #e6e6e6;
          p {
            font-size: 10px;
            color: #01314a;
            margin: 0px;
          }
          .dropdownIcon {
            cursor: pointer;
          }
        }
      }
      //  .monthPickerDropdown {
      //       float: right;
      //       width: 75%;
      //       .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input { padding:6px;border-radius:10px;}
      //       .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {border-radius:8px;
      //       background:#e6e6e6;}
      //       .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {padding:0px;}
      //     }
      .headingAndDropdown {
        display: flex;
        justify-content: space-between;
      }
      .monthAttendanceContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;

        h3 {
          margin: 0px;
          color: #01314a;
        }
      }
      .attendancePrograssBlock {
        margin: 20px 10px 0;
        .headingAndProgressbar {
          display: flex;
          justify-content: space-between;
          line-height: 45px;
          .MuiGrid-root {
            padding: 30px;
          }
          p.organizationField {
            margin: 0;
            color: #7f7f7f;
            font-size: 12px;
            margin-left: 28px;
          }
          .progressBarBgContainer {
            width: 100%;
            top: 0px;
            position: relative;
            .progressBarBgSubContainer {
              p {
                margin: 0px;
                color: #5788a3;
              }
            }
            p {
              margin: 0;
              font-size: 13px;
              color: #7f7f7f;
              line-height: 25px;
              display: flex;
              justify-content: space-between;
              width: 100%;
              span {
                font-size: 21px;
                color: #004b73;
                font-weight: bold;
              }
              div {
                color: #004b73;
              }
            }
            p.percentage {
              display: none;
            }
            .progressBarBg {
              background-color: #ededed;
              height: 10px;
              border-radius: 20px;
              width: 100%;
              .bar {
                width: 0;
                height: 10px;
                transition: width 0.3s;
                background: #00abdc;
                border-radius: 20px;
              }
            }
          }
        }
      }
    }
    /**********************Today Leaves in HRMS dashboard***********************/
    .todayLeavesContainer {
      padding: 18px;
      border-radius: 8px;
      box-shadow: 0px 0px 4px 0px #bbbbbb;
      .applicationInfo {
        h3 {
          margin: 0px 0px 0px 0px;
          color: #01314a;
        }
      }
      .container {
        height: 150px;
      }

      ul.legend {
        width: 225px;
        height: 220px;
        padding: 10px 0;
        overflow-y: auto;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        //justify-content: center;
        li {
          display: flex;
          align-items: center;
          div {
            &:first-child {
              margin-right: 10px;
            }
            &:last-child {
              font-size: 13px;
            }
          }
        }
        /* width */
        &::-webkit-scrollbar {
          width: 6px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #a8afb2;
          border-radius: 5px;
          height: 5px !important;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #92989b;
        }
      }
    }
    .doughnutContainer {
      position: relative;
      .doughnutOne {
        .totalAmount {
          position: absolute;
          top: 35px;
          left: 25px;
          font-size: 25px;
          font-weight: 600;
          width: 84px;
          text-align: center;
        }
        .donutchart {
          overflow: hidden;
        }
        .doughnutChartNiranjan-arcs {
          height: 200px;
        }
        .donutchart-arcs-path,
        .donutchart-legend-item,
        .donutchart-legend-item-label {
          cursor: pointer;
        }
        .donutchart-arcs {
          transform: matrix(0.4, 0, 0, 0.4, 0, 0);
        }
        .donutchart-legend {
          display: none;
        }
        .donutchart-innertext-label {
          font-size: 12px;
          transform: translate(-20%, 12%);
          font-weight: 500;
          fill: #004b73;
        }
        .donutchart-innertext-value {
          font-size: 25px;
          font-weight: bold;
          transform: translateY(-135px);
          display: none;
        }
        .donutchart-arcs-path,
        .donutchart-legend-item {
          fill-opacity: 0;
          animation-timing-function: ease-in-out;
          animation-fill-mode: forwards;
          animation-name: DrawLine, FillIn;
          animation-delay: 0.5s, 2s;
          animation-duration: 3s, 1s;
          stroke-dasharray: 1500;
          stroke-dashoffset: 1500;
        }
        @keyframes DrawLine {
          to {
            stroke-dashoffset: 0;
          }
        }
        @keyframes FillIn {
          from {
            fill-opacity: 0;
          }
          to {
            fill-opacity: 1;
          }
        }
      }
    }

    /**********************upcoming Events in HRMS dashboard***********************/
    .upcomingEventsSection {
      h5 {
        padding: 10px 0px 10px 24px;
        margin: 0px;
        background: #e6e6e6;
      }
      .eventContainer {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        padding: 18px;

        h3 {
          margin: 0px;
          color: #01314a;
        }
      }
      // .monthPickerDropdown {
      //   float: right;
      //   width: 75%;
      //   border-radius:10px;
      //   .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input { padding:6px;border-radius:10px;}
      //   .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {border-radius:8px;
      //   background:#e6e6e6;}
      //   .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {padding:0px;}

      // }

      border-radius: 8px;
      box-shadow: 0px 0px 4px 0px #bbbbbb;
      .upcomingEventsMonth {
        justify-content: space-between;
        align-items: center;
        display: flex;
        padding: 8px;
        border-radius: 8px;
        background: #e6e6e6;
        p {
          font-size: 10px;
          color: #01314a;
          margin: 0px;
        }
        .dropdownIcon {
          cursor: pointer;
        }
      }
      .upcomingEventsBlock {
        height: 163px;
        overflow-y: scroll;
        /* width */
        &::-webkit-scrollbar {
          width: 6px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #a8afb2;
          border-radius: 5px;
          height: 5px !important;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #92989b;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          th {
            background: #f6f6f6;
            color: #0b2131;
            text-align: left;
            padding: 10px 10px 10px 25px;
            font-size: 11px;
          }

          tr {
            border-bottom: 1px solid #e6e6e6;
          }

          td {
            padding: 10px 10px 10px 25px;
            color: #345a6e;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.leaveBalanceCardContainer{
    position: relative;
    padding:0 0 0 20px;

     //width:450px;
.employeeProfileContainer{
    box-shadow:0 0 4px 0 #bbbbbb;
    border-radius:8px;
    background-color: #ffffff; 
}
.employeeProfileBlock{
    justify-content:space-between;
    margin-bottom:14px;
}
.profileBlockContainer{
    display:flex;
}
.ProfileImageBlock{
    border-radius:50%;
    width:70px;
    margin-right:20px;

}
.profileNameBlock{
    color:#295165;
    margin: 0px !important;
    font-size:16px;
}
.profileDesignation{
    color:#345a6e;
    margin: 0px !important;
    font-size:12px;
    
}
.profileDepartment{
    color:#345a6e;
    margin: 0px !important;
    font-size:12px;
    
}
.registerButtonBlock{
    border-radius:8px !important;
    background:#e8f7ff!important;
    border:2px solid #498bb2 !important;
    color:#498bb2 !important;
    padding:4px 16px;
    display:flex;
    font-size:14px;
}
.LeaveBalanceBlock{
    color:#01314a;
    margin: 0px !important;
    font-size:16px;  
}
.LeaveBalanceArrayBlock{
    font-size:12px;
    color:#4e6f81;
    margin:0px!important;
    display:flex;
    justify-content:center;

}
.LeaveBalanceMappingBlock{
    display:flex;
    justify-content: space-between;
    padding:0px 0px 0px 20px;
    overflow: auto;
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
      scrollbar-width: thin;
    }

    &::-webkit-scrollbar {
      width: 9px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #ededed;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a8afb2;
      border-radius: 20px;
      height: 6px;
      border: transparent;
    }
}
.actualLeavesBlock{
    color:#4e6f81;
}
.actualLeavesBlockRed{
    color:red;
}
.currentLeavesBlock{
    font-size:30px;
    color:#295165;
    font-weight:bold;
}
.actualHalfDayLeaves{
    display:flex;
    justify-content:space-between;
    align-items: center;
    label{
        margin:0px;
        color:#4e6f81;
        font-size:12px;
    }
    p{
        margin:0px;
    }
    .companyActualLeavesBlock{
        color:#295165;
        font-weight:bold;
    }
}
.companyDesidedLeaves{
   padding:10px 10px;
   background: #ffffff;
    box-shadow:0 0 20px 0 #bbbbbb;
    border-radius:8px;
    width:124px;
    position:absolute;
    right:0;
    z-index: 3;
}
.companyDesidedLeaves::before{
    content: " ";
    height: 0;
    position: absolute;
    width: 0;
    top: -20px;
    right:24px;
    border: 10px solid transparent;
    border-bottom-color: #ffffff;
}
.companyActualLeavesBlock{
    font-size:24px;
    color:#295165;
}
.InformationIconBlock{
    width:12px;
    position: absolute;
    bottom: 18px;
    right: 29px;
    cursor:pointer;
}
.mainContainerBlock{
    padding:23px 20px 5px 20px;
}
.actualLeavesBlockContainer{
    color:#4e6f81;
    font-size:13px;
}
.slashBlock{
    color:#4e6f81;
    margin:10px;
}
.employeeProfileMainContainer{
    border-bottom:1px solid #c7d7df; 
}
}
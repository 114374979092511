.filterContainer {
    position: absolute;
    right: -10px;
    top: 35px;
    z-index: 5;
    .columnFilterContainer {
      background: #ffffff;
      position: relative;
      padding: 20px;
      border-radius: 15px;
      width: 200px;
      height: 95px;
      box-shadow: 0px -3px 20px -6px darkslategrey;
      .columnFilterList {
        display: flex;
        margin: 5px 0;
        label {
          font-size: 13px;
          line-height: 25px;
          color: #8f9592;
        }
        .regular-checkbox {
          margin: 0;
          margin-right: 10px;
        }
      }
      .columnFilterCheckBoxContent {
        overflow-y: scroll;
        height: 95px;
        /* width */
        &::-webkit-scrollbar {
          width: 5px;
        }
  
        // /* Track */
        // &::-webkit-scrollbar-track {
        //   background: #f1f1f1;
        // }
  
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #007fad;
          border-radius: 5px;
        }
  
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #bdbdbd;
        }
      }
      &:before {
        content: " ";
        height: 0;
        position: absolute;
        width: 0;
        top: -20px;
        right: 40px;
        border: 10px solid transparent;
        border-bottom-color: #ffffff;
      }
    }
  }
  .filterDropdownArrow {
    background: none !important;
    outline: none;
    border: none;
    color: #ffffff;
    position: absolute;
    right: 20px;
    z-index: 5;
    cursor: pointer;
  }
  
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  height: 87%;
  width: 70%;
  overflow-y: auto;
  background: white;
  padding: 15px;
  border-radius: 8px;
  margin-left: 15%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.gstpopup-content {
  height: 100%; /* Increased height */
  width: 120%; /* Increased width */
  overflow-y: auto;
  background: white;
  padding: 15px;
  margin-left: 15%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adjust the gap between elements */
}

@media (max-width: 768px) {
  .popup-content,
  .gstpopup-content {
    width: 90%; /* Adjust for smaller screens */
  }
}


.gridContainer {
  position: relative;
  .consoleFormButtonBlock {
    button {
      margin-left: 10px;
    }
  }
  .gridComponent {
    // position: absolute;
    // padding-top: 10px;
    table {
      width: 100%;
      border-collapse: collapse;
      font-size: 14px;
    }
    table th {
      // background: #007fad;
      padding: 0 10px;
      height: 50px;
      color: #ffffff;
      font-weight: 100;
      border-right: 1px solid #c7d7df;
      text-align: left;
      white-space: nowrap;
    }

    // table th:nth-child(2) {
    //   background: #007fad;
    //   padding: 12px 10px;
    //   color: #ffffff;
    //   font-weight: 100;
    //   border-right: 1px solid #c7d7df;
    //   border-radius: 10px 0px 0px 0px !important;
    //   text-align: left;
    // }
    table th input[type="checkbox"] {
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid #acc4d1;
      padding: 9px;
      border-radius: 5px;
      display: inline-block;
      position: relative;
    }
    table th input[type="checkbox"]:checked {
      background-color: #004b73;
      border: 2px solid #004b73;
      color: #ffffff;
    }
    table th input[type="checkbox"]:checked:before {
      position: absolute;
      left: -3px;
      top: 43%;
      height: 34%;
      width: 2px;
      background-color: #ffffff;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
    table th input[type="checkbox"]:checked:after {
      position: absolute;
      left: -2px;
      bottom: 4px;
      height: 2px;
      width: 70%;
      background-color: #ffffff;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
    table th:first-child {
      //border-radius: 10px 0 0 0;
      // border-right: none;
    }

    table th:last-child {
      //border-radius: 0 10px 0 0;
      border-right: none;
      width:8%;
    }
    table tr:nth-child(even) {
      background-color: #ffffff;
    }
    table tr:nth-child(odd) {
      background-color: #ebfaff;
    }
    table thead tr:nth-child(1) {
      background-color: transparent;
      border-radius: 10px 10px 0 0;
    }
    table td {
      padding: 7px 10px;
      border-right: 1px solid #d0e0e7;
      color: #969c99;
      white-space: nowrap;
    }
    table td:first-child {
      // border-right: none;
    }
    table td:last-child {
      border-right: none;
    }
    table td input[type="checkbox"] {
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid #acc4d1;
      padding: 9px;
      border-radius: 5px;
      display: inline-block;
      position: relative;
      cursor: pointer !important;
    }
    table td input[type="checkbox"]:checked {
      background-color: #004b73;
      border: 2px solid #004b73;
      color: #ffffff;
    }
    table td input[type="checkbox"]:checked:before {
      position: absolute;
      left: -3px;
      top: 38%;
      height: 45%;
      width: 2px;
      background-color: #ffffff;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
    table td input[type="checkbox"]:checked:after {
      position: absolute;
      left: -3px;
      bottom: 3px;
      height: 2px;
      width: 80%;
      background-color: #ffffff;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
    .buttonBlock {
      display: flex;
      justify-content: end;
      background-color: #ebfaff;
      padding: 10px 15px;
      border-radius: 10px;
      .search-label span {
        display: none;
      }
      // #search-bar-0 {
      //   padding: 10px;
      //   border: 1px solid #b0b0b0;
      //   border-radius: 10px;
      //   margin-right: 10px;
      // }
      .exportButton {
        margin-right: 10px;
      }
    }
    .react-bootstrap-table {
      margin-top: 10px;
      background-color: #007fad;
      border-radius: 10px 10px 0 0;
      overflow-y: auto;
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
        scrollbar-width: thin;
      }
      &::-webkit-scrollbar {
        width: 9px;
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #a8afb2;
        border-radius: 20px;
        height: 6px;
        border: transparent;
      }
    }
    // .react-bs-table-sizePerPage-dropdown {
    //   display: none;
    // }
    .pagination {
      display: flex;
      justify-content: end;
      .active.page-item {
        padding: 1px 10px;
        background: #e2e9ed;
        margin: 0 10px 0 0;
        border-radius: 5px;
        a {
          color: #044e75;
        }
      }
      .page-item {
        padding: 1px 7px;
        margin: 0 10px 0 0;
        a {
          color: #a5bdcc;
          font-size: 13px;
        }
      }
    }
    .react-bootstrap-table-pagination {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
      button {
        background-color: #004b73;
        border: none;
        border-radius: 5px;
        line-height: 25px;
        color: #ffffff;
        cursor: pointer;
        margin: 0 2px;
      }
    }
    // .react-bs-table-sizePerPage-dropdown {
    //   float: left;
    //   display: flex;
    //   margin: 16px 0;
    //   .dropdown-menu{margin:0;}
    //   ul {
    //     display: flex;
    //     float: left;
    //     li{padding:5px;}
    //   }
    // }
  }
  .react-bootstrap-table-pagination-total {
    font-size: 13px;
    margin-left: 10px;
  }
  .gridComponentNoBorder {
    padding-top: 40px;
    table {
      width: 100%;
      border-collapse: collapse;
      font-size: 14px;
    }
    table th {
      background: #007fad;
      padding: 12px 10px;
      color: #ffffff;
      font-weight: 100;
      border-right: none;
      text-align: center;
    }
    table th:first-child {
      border-radius: 10px 0 0 0;
      border-right: none;
    }

    table th:last-child {
      border-radius: 0 10px 0 0;
      border-right: none;
    }
    table tr:nth-child(even) {
      background-color: #ffffff;
    }
    table tr:nth-child(odd) {
      background-color: #ebfaff;
    }
    table td {
      padding: 5px 10px;
      border-right: none;
      color: #888e8b;
      span {
        background: #e2f2f7;
        border-radius: 10px;
        text-align: center;
        padding: 10px 10px;
        display: block;
        margin: 5px;
      }
    }
    table td:first-child {
      border-right: none;
    }
    table td:last-child {
      border-right: none;
    }

    .buttonBlock {
      display: flex;
      justify-content: end;
      .search-label span {
        display: none;
      }
      .exportButton {
        margin-right: 10px;
      }
    }
    .react-bootstrap-table {
      margin-top: 10px;
    }
    // .react-bs-table-sizePerPage-dropdown {
    //   display: none;
    // }
    .pagination {
      display: flex;
      justify-content: end;
      .active.page-item {
        padding: 1px 10px;
        background: #e2e9ed;
        margin: 0 10px 0 0;
        border-radius: 5px;
        a {
          color: #044e75;
        }
      }
      .page-item {
        padding: 1px 7px;
        margin: 0 10px 0 0;
        a {
          color: #a5bdcc;
          font-size: 13px;
        }
      }
    }
  }
  .cloumnButtonBlock {
    button {
      margin-right: 10px;
    }
  }
  .react-bs-table-no-data {
    height: 250px;
    text-align: center;
  }
}
.userGridContainer {
  position: relative;
  .userGridComponent {
    // position: absolute;
    padding-top: 40px;
    table {
      width: 100%;
      border-collapse: collapse;
      font-size: 14px;
    }
    // table tr {
    //   border-bottom: 1px solid #d0e0e7;
    // }
    table th {
      background: #007fad;
      padding: 15px 10px;
      color: #ffffff;
      font-weight: 100;
      border-right: 1px solid #c7d7df;
      text-align: left;
    }
    table th.tableHeadBlock {
      text-align: center !important;
    }
    table th input[type="checkbox"] {
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid #acc4d1;
      padding: 9px;
      border-radius: 5px;
      display: inline-block;
      position: relative;
    }
    table th input[type="checkbox"]:checked {
      background-color: #004b73;
      border: 2px solid #004b73;
      color: #ffffff;
    }
    table th input[type="checkbox"]:checked:before {
      position: absolute;
      left: -3px;
      top: 38%;
      height: 45%;
      width: 2px;
      background-color: #ffffff;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
    table th input[type="checkbox"]:checked:after {
      position: absolute;
      left: -2px;
      bottom: 5px;
      height: 2px;
      width: 65%;
      background-color: #ffffff;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
    table th:first-child {
      border-radius: 10px 0 0 0;
      // border-right: none;
    }

    table th:last-child {
      border-radius: 0 10px 0 0;
      border-right: none;
    }
    table td {
      padding: 12px 10px 5px;
      border-right: 1px solid #d0e0e7;
      color: #969c99;
    }
    table td.tableHeadBlock {
      text-align: center !important;
    }
    table td:last-child {
      border-right: none;
    }
    table td input[type="checkbox"] {
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid #acc4d1;
      padding: 9px;
      border-radius: 5px;
      display: inline-block;
      position: relative;
    }
    table td input[type="checkbox"]:checked {
      background-color: #004b73;
      border: 2px solid #004b73;
      color: #ffffff;
    }
    table td input[type="checkbox"]:checked:before {
      position: absolute;
      left: -3px;
      top: 38%;
      height: 45%;
      width: 2px;
      background-color: #ffffff;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
    table td input[type="checkbox"]:checked:after {
      position: absolute;
      left: -2px;
      bottom: 5px;
      height: 2px;
      width: 65%;
      background-color: #ffffff;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
    table td button {
      width: 25px;
      height: 25px;
      margin-right: 15px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    // table {
    //   .userGridSecondTable {
    //     width: 100%;
    //     display: contents;
    //     table tr:nth-child(even) {
    //       background-color: #ebfaff;
    //     }
    //     table tr:nth-child(odd) {
    //       background-color: #fff;
    //     }
    //   }
    // }

    .react-bootstrap-table {
      margin-top: 10px;
    }
    // .react-bs-table-sizePerPage-dropdown {
    //   display: none;
    // }
    // .header-class{
    //   background-color: #004b73 !important;
    // }

    .pagination {
      display: flex;
      justify-content: end;
      .active.page-item {
        padding: 1px 10px;
        background: #e2e9ed;
        margin: 0 10px 0 0;
        border-radius: 5px;
        a {
          color: #044e75;
        }
      }
      .page-item {
        padding: 1px 7px;
        margin: 0 10px 0 0;
        a {
          color: #a5bdcc;
          font-size: 13px;
        }
      }
    }
  }
}
.payslipResultContainer {
  .payslipTable {
    th {
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
        padding-right: 20px;
      }
      .documentDownload {
        margin-top: 0;
      }
    }
    td {
      line-height: 30px;
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }
      .documentDownload {
        margin-top: 0;
      }
    }
  }
}
/****************************************************************************************/
.payRegisterView {
  position: relative;
  .pdfAndExcelButtons {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 5px;
    .button {
      min-width: auto !important;
      padding: 5px 10px !important;
      img {
        margin: 0;
      }
    }
  }
}
/***************************************Batch planning form*****************************/
.batchPlanningContainer {
  .consoleFormButtonBlock {
    padding: 0 !important;
    button {
      margin-top: 32px;
    }
  }
}
.batchPlanningGrid {
  margin-top: 20px;
  .gridComponent {
    table {
      tbody {
        display: block;
        min-height: 265px;
        max-height: 265px;
        overflow: auto;
        background-color: #ffffff;
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
          scrollbar-width: thin;
        }

        &::-webkit-scrollbar {
          width: 9px;
          height: 6px;
        }

        &::-webkit-scrollbar-track {
          background: #ededed;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #a8afb2;
          border-radius: 20px;
          height: 6px;
          border: transparent;
        }
      }
      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
        td {
          white-space: initial !important;
          overflow-wrap: break-word;
          .closeButtonForInput {
            align-items: center !important;
            height: inherit !important;
          }
        }
      }
      th{
        &:last-child{padding-right:10px;}
      }
    }
  }
}

.InwardEntryButtonContainer{
    display:flex;
    align-items:center;
    margin-right:10px;
}
.inwardEntryContainer{
  .css-mhc70k-MuiGrid-root>.MuiGrid-item {
    padding-left:0!important;
  }
}
.taxLabel{
  margin-bottom: 8px;
    font-size: 13px;
    color: #808080;
    font-family: 'poppins-regular';
}


.poListContainer{
    margin-bottom: 8px;
font-size: 13px;
color: #808080;
font-family: "poppins-regular";
}
.consoleTextFieldBlock .inputTextFieldContainer{
    display: block;
    outline:none;
    border-radius: 10px;
height: 45px;
border: 1px solid #ccd6db;
margin-top: 5px;
width: -webkit-fill-available;
color: #bbbbbb;
font-size: 15px;
font-family: 'poppins-regular';
padding: 0 10px
  
}
.inputTextFieldContainer::placeholder{
    font-size:16px;
    color:#ccd6db;
}
.consoleComponentsDeletePopup {
    padding: 13px;
    .deleteHeader {
      padding-left: 10px;
    }
    .closeIcon {
      position: absolute;
      border: none;
      top: 20px;
      right: 15px;
      background: none;
    }
    .closeIcon:hover {
      opacity: 0.3;
      cursor: pointer;
    }
    h1 {
      text-align: left;
      font-size: 21px;
      color: #01314a;
      font-family: 'poppins_Regular';
    }
    p {
      color: #1e485e;
      font-size: 15px;
      text-align: left;
      margin: 0;
      font-family: 'poppins_Regular';
    }
    .buttonInfo {
      display: flex;
      align-items: right;
      padding-top: 32px;
      justify-content: flex-end;
    }
    .buttonInfo .deleteButton {
      padding: 10px 28px;
      border: 1px solid #a0de00;
      background-color: white;
      text-align: center;
      border-radius: 12px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      color: #a0de00;
      font-family: 'poppins_Regular';
      text-transform: none;
    }
    .buttonInfo .deleteButton:hover {
      color: white;
      font-family: 'poppins_Regular';
      text-transform: none;
      background: #a0de00;
    }
    .deletePopup .deleteIcon {
      height: 21px;
      padding-right: 10px;
    }
    .buttonInfo .cancelButton {
      background-color: #a0de00;
      border: none;
      text-align: center;
      border-radius: 12px;
      padding: 10px 45px;
      align-items: center;
      display: flex;
      color: white;
      font-family: 'poppins_Regular';
      text-transform: none;
    }
    .buttonInfo .cancelButton:hover {
      color: #a0de00;
      border: 1px solid #a0de00;
      background: white;
    }
  }
  .consoleComponentsDeletePopup .MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation24 MuiDialog-paper MuiDialog-paperScrollPaper MuiDialog-paperWidthSm css-b7otfo-MuiPaper-root-MuiDialog-paper{
      WIDTH: 100% !important;
  }
  .deleteHeader .consoleTextFieldBlock .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 3.5px 4px 3.5px 6px!important;
  }
  .deleteHeader {
    .autoCompleteDropdownContainer .css-wb57ya-MuiFormControl-root-MuiTextField-root {
        padding:0!important;
        margin-top:0!important;
    }
     .buttonInfo {
        justify-content:center!important;
    }
     .buttonInfo .deleteButton {
         background:#a0de00;
         color:white;
         padding:10px 55px;
     }
      h1 {
         margin:0!important;
     }
 
    }
    .consoleUpdateTextBlock{
      display: flex;
      align-items: center;
      margin-top: 37px;
      justify-content: space-between;
    }
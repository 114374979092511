.payslipTable {
  box-shadow: 0px 0px 10px -8px;
  thead {
    display: block;
  }
  tbody {
    display: block;
    min-height: 250px;
    max-height: 250px;
    overflow: auto;
    &::-webkit-scrollbar {
      height: 5px; /* height of horizontal scrollbar ← You're missing this */
      width: 5px; /* width of vertical scrollbar */
      border: 1px solid #d5d5d5;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #a8afb2;
      border-radius: 5px;
      height: 5px !important;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #92989b;
    }
  }

  td {
    &:first-child {
      width: 80%;
    }
    &:last-child {
      width: 20%;
    }
  }
  
}

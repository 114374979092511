/*TextField*/
.consoleFormContainer {
  padding: 20px 0 0;

  h5 {
    font-family: "poppins-regular";
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 5px;
    color: #004b73;
  }

  h6 {
    font-family: "poppins-regular";
    font-size: 16px;
    padding: 15px 20px;
    margin: 0;
    color: #004b73;
    border-bottom: 1px solid #cccccc;
  }

  .consoleFormContent {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #d6d6d6;
  }

  .consoleFormBlock1 {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
  }

  .rdt {
    position: relative;

    input {
      width: 90%;
      padding: 15px 10px;
      border-radius: 10px;
      outline: none;
      border: 1px solid #ccd6db;
      margin-top: 4px;
    }
  }

  .rdtPicker {
    margin-top: 9px !important;
    margin-left: 10px;
    display: none;
    position: absolute;
    min-width: 250px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #f9f9f9;
  }

  .rdtOpen .rdtPicker {
    display: block;
  }

  .rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
  }

  .rdtPicker .rdtTimeToggle {
    text-align: center;
  }

  .rdtPicker table {
    width: 100%;
    margin: 0;
  }

  .rdtPicker td,
  .rdtPicker th {
    text-align: center;
    height: 28px;
  }

  .rdtPicker td {
    cursor: pointer;
  }

  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
  }

  .rdtPicker td.rdtOld,
  .rdtPicker td.rdtNew {
    color: #999999;
  }

  .rdtPicker td.rdtToday {
    position: relative;
  }

  .rdtPicker td.rdtToday:before {
    content: "";
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #428bca;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
  }

  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: #428bca;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }

  .rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
  }

  .rdtPicker td.rdtDisabled,
  .rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }

  .rdtPicker td span.rdtOld {
    color: #999999;
  }

  .rdtPicker td span.rdtDisabled,
  .rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }

  .rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
  }

  .rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
  }

  .rdtPicker th.rdtSwitch {
    width: 100px;
  }

  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
  }

  .rdtPrev span,
  .rdtNext span {
    display: block;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
  }

  .rdtPicker th.rdtDisabled,
  .rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }

  .rdtPicker thead tr:first-of-type th {
    cursor: pointer;
  }

  .rdtPicker thead tr:first-of-type th:hover {
    background: #eeeeee;
  }

  .rdtPicker tfoot {
    border-top: 1px solid #f9f9f9;
  }

  .rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
  }

  .rdtPicker button:hover {
    background-color: #eee;
  }

  .rdtPicker thead button {
    width: 100%;
    height: 100%;
  }

  td.rdtMonth,
  td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
  }

  td.rdtMonth:hover,
  td.rdtYear:hover {
    background: #eee;
  }

  .rdtCounters {
    display: inline-block;
  }

  .rdtCounters>div {
    float: left;
  }

  .rdtCounter {
    height: 100px;
    width: 40px;
  }

  .rdtCounterSeparator {
    line-height: 100px;
  }

  .rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;

    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
  }

  .rdtCounter .rdtBtn:hover {
    background: #eee;
  }

  .rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
  }

  .rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
  }

  .rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
  }

  .rdtTime td {
    cursor: default;
  }

  .timePicker {
    position: relative;

    .MuiSvgIcon-root {
      position: absolute;
      top: 17px;
      right: 15px;
      color: #bfccd2;
    }
  }

  // .rdt {
  //   input {
  //     width: 90%;
  //     padding: 15px 10px;
  //     border-radius: 10px;
  //     outline: none;
  //     border: 1px solid #ccd6db;
  //     margin-top: 4px;
  //   }

  //   .rdtPicker {
  //     border-radius: 8px;
  //     background-color: #ffffff;
  //     padding: 20px;
  //     z-index: 1;
  //     margin-top: 15px;
  //     box-shadow: 0px -3px 20px -6px darkslategrey;
  //     ::before {
  //       content: " ";
  //       height: 0;
  //       position: absolute;
  //       width: 0;
  //       top: -20px;
  //       right: 40px;
  //       border: 10px solid transparent;
  //       border-bottom-color: #ffffff;
  //     }
  //     .rdtTime {
  //       display: flex;
  //       justify-content: center;
  //       .rdtCounters {
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;
  //         .rdtCounter {
  //           width: 50px;
  //           text-align: center;
  //           .rdtBtn {
  //             cursor: pointer;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

.dotPointer {
  height: 5px;
  width: 5px;
  border: 1px solid;
  border-radius: 50%;
  background-color: cadetblue;
  margin-right: 5px;
}

// .consoleFormBlock {
//   background: #ffffff;
//   padding: 20px;
//   border-radius: 10px;
//   box-shadow: 0px 0px 10px #d6d6d6;
// }
.consoleFormBlock {
  background-color: #ebfaff;
  border-radius: 10px;
  padding: 20px;
  border: 2px solid #d7f5ff;

  label {
    margin-bottom: 8px;
    font-size: 13px;
    color: #808080;
    font-family: "poppins-regular";

    .starcolor {
      color: #fa234a;
    }
  }
}

.TextFieldBlock {
  label {
    margin-bottom: 8px;
    font-size: 13px;
    color: #808080;
    font-family: "poppins-regular";

    .starcolor {
      color: #fa234a;
    }
  }

  .TextField {
    border-radius: 10px;
    height: 45px;
    border: 1px solid #ccd6db;
    margin-top: 5px;
    width: -webkit-fill-available;
    color: #004b73;
    font-size: 15px;
    font-family: "poppins-regular";
    padding: 0 10px;

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: #ccd5da;
      opacity: 1;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #ccd5da;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #ccd5da;
    }
  }

  .text-danger {
    color: #fa234a;
    font-size: 12px;
    padding-left: 10px;
  }

  .errorTextField {
    border: 1px solid #fa234a;
  }
}

/****************************************MonthPicker  Dropdown Component********************************************************/
.monthPickerDropdown {
  width: 70%;
}

.css-ew73zq-MuiModal-root-MuiDialog-root {
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: none;
}

//*customizegridComponent with newdatepicker*//
.gridMonthSelection {
  position: relative;

  .gridContainer .gridComponent .buttonBlock {
    justify-content: space-between !important;
    align-items: center;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .MuiInputBase-root-MuiOutlinedInput-root {
    background-color: #c9e2ec !important;
    color: #007fad !important;
    font-weight: bold !important;
    font-size: 13px !important;
    border: 1px solid transparent !important;
    border-radius: 10px;
  }

  .MuiSvgIcon-root {
    color: #007fad;
  }

  .MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px 10px;
  }
}

//*Upload*//
.documentUploadContent {
  width: 100%;

  .documentUpload {
    width: 100%;
  }
}

.uploadBlock {
  .uploadBtn {
    padding: 31px 20px !important;
    border: 1px solid #ccd6db !important;
    border-radius: 10px !important;
    // width: 100%;
    // height: 165px;
    height: 320px;
    width: 100%;
    margin-bottom: 0;

    .uploadInfo {
      text-transform: none;
      text-align: center;
      color: #7f7f7f;
      font-family: "poppins-regular";

      h1 {
        margin: 0;
        font-weight: normal;
      }

      h3 {
        margin: 0;
        font-weight: normal;
        margin-bottom: 10px;
      }

      p {
        font-size: 11px;
        margin: 0px;
      }
    }
  }

  .imagePreview {
    border: 1px solid #ccd6db !important;
    border-radius: 10px !important;
    height: 320px;
    width: 100%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }

    .editIconStyle {
      position: absolute;
      width: 30px;
      height: 30px;
      padding: 0;
      //box-shadow: 0 0 10px 0;
      background: none;
      top: 5px;
      right: 5px;
      border: none;
      cursor: pointer;
      border-radius: 50%;
    }
  }
}

/*DatePicker*/
.consoleDatePicker {
  border-radius: 10px;
  height: 25px;
  border: 1px solid #ccd6db;
  margin-top: 5px;
  display: flex !important;
  justify-content: space-between;
  padding: 10px;
  background-color: #ffffff;

  input {
    border: none;
    width: 100%;
    outline: none;
    color: #004b73;
    font-size: 15px;

    &::placeholder {
      color: #ccd5da;
      opacity: 1;
      font-size: 15px;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #dacfcc;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #ccd5da;
    }
  }

  .MuiIconButton-edgeEnd {
    color: #bfccd2 !important;
  }
}

.consoleAdormentBlock {
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid#dbcccc !important;
  }

  h5 {
    font-family: "poppins-regular";
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 5px;
    color: #004b73;
  }

  label {
    margin-bottom: 8px;
    font-size: 13px;
    color: #7f7f7f;
  }

  .MuiTextField-root {
    margin: 5px 0 0 0;
  }

  .MuiOutlinedInput-root {
    margin: 0;
    padding: 0;
    height: 46px;
    border-radius: 10px;
    background: #fff;
  }
  .Mui-disabled{
    border-radius: 10px;
    margin: 0;
    padding: 0 7px;
    height: 46px;
    background:#ccd6db !important;

  }

  input {
    padding: 11px 10px;
    border-right: 1px solid #ccd6db;
  }

  button {
    text-transform: none;
    margin: 0 10px 0 0;
    padding: 0;
    color: #9fb1bb;
  }

  .MuiInputAdornment-root {
    justify-content: center;
    margin: 0;

    span {
      display: block;
      margin: 0 15px;
    }
  }
}

.consoleAdormentImgBlock {
  h5 {
    font-family: "poppins-regular";
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 5px;
    color: #004b73;
  }

  img {
    width: 17px;
    padding-right: 10px;
    cursor: pointer;
  }

  label {
    margin-bottom: 8px;
    font-size: 13px;
    color: #7f7f7f;
  }

  .MuiTextField-root {
    margin: 5px 0 0 0;
  }

  .MuiOutlinedInput-root {
    margin: 0;
    padding: 0;
    height: 46px;
    border-radius: 10px;
    background: #fff;
  }

  input {
    padding: 11px 10px;
    // border-right: 1px solid #ccd6db;
  }

  button {
    text-transform: none;
    margin: 0 10px 0 0;
    padding: 0;
    color: #9fb1bb;
  }

  .MuiInputAdornment-root {
    justify-content: center;
    margin: 0;

    span {
      display: block;
      margin: 0 15px;
    }
  }
}

/*Document Upload*/
.ducumentUploadBlock {
  display: flex;

  .hiddenInput {
    display: none;
  }

  .disabled {
    border: 1px dashed #d4d4d4 !important;
    border-radius: 10px;
    margin-top: 5px;
    text-transform: none;
    color: lightgrey !important;
    height: 45px;
    background: #efefef !important;
    box-shadow: none;

    img {
      filter: grayscale(1);
      opacity: 0.4;
    }
  }

  .documentUpload {
    border: 1px dashed #94cd00;
    border-radius: 10px;
    margin-top: 5px;
    text-transform: none;
    color: #94cd00;
    height: 45px;
    background: transparent;
    box-shadow: none;

    &:hover,
    &:focus {
      background-color: #f6ffe0 !important;
      border: 1px dashed #94cd00;
      box-shadow: none !important;
      border-radius: 10px;
      color: #94cd00;
    }
  }

  .documentDownload {
    border: 1px dashed #94cd00;
    border-radius: 10px;
    margin-top: 5px;
    text-transform: none;
    color: #94cd00;
    line-height: 45px;
    background: transparent;
    box-shadow: none;
    width: 100%;
    text-align: center;
    margin-right: 10px;
    align-items: center;
    display: flex;
    justify-content: center;

    svg {
      margin-right: 5px;
    }

    &:hover,
    &:focus {
      background-color: #f6ffe0 !important;
      border: 1px dashed #94cd00;
      box-shadow: none !important;
      border-radius: 10px;
      color: #94cd00;
    }
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .uploadedInfo {
    line-height: normal;
    margin-top: 0px;
    height: 35px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 10px -7px;
    align-items: center;
    padding: 6px;
    border-radius: 10px;
    background: #f4f4f4;

    .uplodedFileName {
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .tickIcon {
      width: 20px;
      height: 15px;
    }

    .selectedFileName {
      font-size: 13px;
      text-overflow: ellipsis;
      overflow: hidden;
      // width: 100px;
      white-space: nowrap;
    }

    .deleteButton {
      border: none;
      background: none;
      cursor: pointer;

      img {
        width: 30px;
        height: 30px;
        margin: 0;
        display: flex;
        align-items: center;
      }
    }
  }
}

/**********************************Supplier Master************************/
.SupplierMasterContainer {
  .documentUpload {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: auto !important;
    margin-top: 27px;
  }

  .uploadedInfo {
    display: flex;
    align-items: center;
    padding: 6px;
    margin: 5px 0px;
    border-radius: 10px;
    background: #f4f4f4;
    line-height: 15px;
    margin-top: 27px;
    justify-content: space-around;
    font-size: 12px;

    p {
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.btn_upload {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  color: #fff;
  background-color: white;
  border: 1px dashed #94cd00;
  padding: 5px 10px;
  border-radius: 10px;
}

.btn_upload:hover,
.btn_upload:focus {
  background-color: #ffffff !important;
  border: 1px dashed #94cd00;
  box-shadow: none !important;
  border-radius: 10px;
  color: #94cd00;
}

.btn_upload input {
  cursor: pointer;
  height: 100%;
  position: absolute;
  filter: alpha(opacity=1);
  -moz-opacity: 0;
  opacity: 0;
}

/*Button Block*/
.consoleFormButtonBlock {
  padding: 20px 20px;
  display: flex;
  justify-content: center;

  .button {
    margin: 0 5px;
  }

  .cancelButton {
    background-color: #ffffff !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #94cd00;
    text-transform: none;
    font-family: "poppins-regular";
    margin: 0 10px 0 0;
    font-size: 13px;
    border: 1px solid #94cd00;
  }

  .cancelButton:hover {
    background-color: #94cd00 !important;
    box-shadow: none;
    border-radius: 10px;
    color: #ffffff;
    border: 1px solid transparent;
  }

  .saveButton {
    background-color: #94cd00;
    box-shadow: none;
    border-radius: 10px;
    color: #ffffff;
    text-transform: none;
    font-family: "poppins-regular";
    font-size: 13px;
    margin: 0 10px 0 0;
    border: 1px solid transparent;
    padding: 5px 30px;
  }

  .saveButton:hover {
    background-color: #ffffff !important;
    border: 1px solid #94cd00;
    box-shadow: none !important;
    border-radius: 10px;
    color: #94cd00;
  }

  .closeButton {
    background-color: red;
    box-shadow: none;
    border-radius: 10px;
    color: #ffffff;
    text-transform: none;
    font-family: "poppins-regular";
    font-size: 13px;
    margin: 0 10px 0 0;
    border: 1px solid transparent;
    padding: 5px 30px;
  }

  .closeButton:hover {
    background-color: #ffffff !important;
    border: 1px solid red;
    box-shadow: none !important;
    border-radius: 10px;
    color: red;
  }

  .cancleButton {
    background-color: #dc143c;
    box-shadow: none;
    border-radius: 10px;
    color: #ffffff;
    text-transform: none;
    font-family: "poppins-regular";
    font-size: 13px;
    margin: 0 10px 0 0;
    border: 1px solid transparent;
    padding: 5px 30px;
  }

  .cancleButton:hover {
    background-color: #ffffff !important;
    border: 1px solid #dc143c;
    box-shadow: none !important;
    border-radius: 10px;
    color: #dc143c;
  }

  .rejectButton {
    background-color: #cd0000;
    box-shadow: none;
    border-radius: 10px;
    color: #ffffff;
    text-transform: none;
    font-family: "poppins-regular";
    font-size: 13px;
    margin: 0 10px 0 0;
    border: 1px solid transparent;
    padding: 5px 30px;
  }

  .rejectButton:hover {
    background-color: #ffffff !important;
    border: 1px solid #cd0000;
    box-shadow: none !important;
    border-radius: 10px;
    color: #cd0000;
  }

  .updateButton {
    background-color: #030e82;
    box-shadow: none;
    border-radius: 10px;
    color: #ffffff;
    text-transform: none;
    font-family: "poppins-regular";
    font-size: 13px;
    margin: 0 10px 0 0;
    border: 1px solid transparent;
    padding: 5px 30px;
  }

  .updateButton:hover {
    background-color: #ffffff !important;
    border: 1px solid #030e82;
    box-shadow: none !important;
    border-radius: 10px;
    color: #030e82;
  }
}

/*Aadhar Verification Block*/
.aadharVerificationBlock {
  width: 45%;
  margin: 50px auto;

  .consoleAdormentImgBlock img {
    padding-right: 20px !important;
    cursor: pointer;
    width: 26px;
  }

  .TextFieldBlock label {
    margin-bottom: 8px;
    font-size: 20px !important;
    color: #4e6f81 !important;
  }

  .consoleAdormentImgBlock .MuiOutlinedInput-root {
    margin: 0 auto;
    padding: 0;
    height: 71px !important;
    border-radius: 10px;
    background: #fff;
  }

  .aadharVerificationContent {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: #4e6f81;
      font-size: 14px;
      margin-right: 10px;
    }

    a {
      border-bottom: 1px solid #01314a;
      color: #01314a;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .consoleAdormentImgBlock input {
    /* padding: 11px 10px; */
    font-size: 25px;
  }

  .MuiInputBase-root-MuiOutlinedInput-root {
    border-color: #d0e0e7 !important;
  }

  // .consoleAdormentImgBlock img {
  //   width: 26px;
  // }

  .button.greenButton {
    background-color: #94cd00 !important;
    color: #ffffff !important;
    font-size: 13px !important;
    border: 1px solid transparent !important;
    padding: 0 40px;
  }
}

.multiSelectDropdown {
  width: 100% !important;

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: 2px solid #dee7ec !important;
    border-radius: 10px;

    legend {
      display: none;
    }
  }

  .multiSelectContent {
    position: absolute !important;
    top: 14px;

    .iconSize {
      width: 40px;
      height: 40px;
      left: 55px;
      position: absolute;
      top: -8px;
    }

    .moduleName {
      position: absolute !important;
      top: 0;
      left: 110px;
      color: #004b73;
      font-family: "poppins-regular";
    }

    .mainCheckbox {
      position: absolute !important;
      top: 0;
      padding: 0;
      left: 20px;

      .css-i4bv87-MuiSvgIcon-root {
        fill: #004b73 !important;
        border-radius: 15px;
      }
    }
  }

  .MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #dee7ec;
  }

  .MuiSvgIcon-root-MuiSelect-icon {
    fill: #004b73 !important;
  }
}

.multiSelectOption {
  .MuiSvgIcon-root {
    fill: #004b73 !important;
    border-radius: 15px;
  }
}

.roleAndCompanySelectionContainer {
  margin-bottom: 20px;

  .roleAndCompanySelection {
    display: flex;
    align-items: end;
    margin-bottom: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;

    .buttonBlock {
      justify-content: center;
      display: flex;

      img {
        margin: 0;
      }
    }
  }
}

/*******************************************HRMS Employee Database****************************************************************/

/*personalDetails*/

.personalDetailsInfo {
  h1 {
    color: #01314a;
    font-size: 14px;
    margin: 0px 0px 10px 0px;
  }

  // .uploadBlock .uploadBtn {
  //   border: 1px solid #ccd6db !important;
  //   border-radius: 10px !important;
  //   height: 242px;
  // }
  .personalDetailsUpload {
    .ducumentUploadBlock {
      .documentUploadContent {
        .documentUpload {
          display: flex;
          justify-content: center;
          align-items: center;
          width: auto;
          margin-top: 25px;
          height: 35px;
        }

        .uploadedInfo {
          line-height: normal;
          margin-top: 20px;
          margin-top: 25px;
          height: 35px;

          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .greenBorderButton {
    margin-right: 10px;
  }
}

/*******************************Educational details*****************/
.educationalDetailsBlock {
  .educational_details_table{
    overflow: auto;
  }
  .gridContainer {
    // overflow-x: auto;
    margin-top: 10px;
    margin-bottom: 10px;

    &::-webkit-scrollbar {
      height: 5px;
      /* height of horizontal scrollbar ← You're missing this */
      width: 5px;
      /* width of vertical scrollbar */
      border: 1px solid #d5d5d5;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #a8afb2;
      border-radius: 5px;
      height: 5px !important;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #92989b;
    }

    .gridComponent {
      /* scrollbar */
      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      } 
      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-border-radius: 10px;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      }
      ::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(255, 255, 255, 0.3);
      }
      .customDropdown {
        label {
          margin-bottom: 8px;
          font-size: 13px;
          color: #808080;
          font-family: "poppins-regular";
        }
        .react-select__control{
          margin-top: 5px;
          border: 1px solid #ccd6db;
          border-radius: 10px !important;
          line-height: 33px;
          box-shadow: none;
          line-height: normal !important;
          margin-top: 0 !important;
          min-width: 250px;
          &:focus-visible {
            outline: none;
          }
          .react-select__menu-portal{
            white-space: break-spaces;
            word-break: break-word;
          }
        }
        .react-select__indicator-separator{
          display: none;
        }
        .react-select__placeholder {
          color: #ccd5da;
        }
        select {
          margin-top: 5px;
          border-radius: 10px !important;
          padding: 11px 10px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none; /* Remove default arrow */
          background-image: url(../images/dropdownArrow.png); /* Add custom arrow */
          background-repeat: no-repeat;
          background-position: calc(100% - 15px) calc(100% - 15px);
          background-size: 15px;
          font-family: "poppins-regular";
          font-size: 15px;
          border: 1px solid #b3c1c9;
          &:focus-visible {
            outline: none;
          }
        }
      }
      table {
        width: 100%;
        display: block;
        // overflow-x: auto;
        white-space: nowrap;

        th {
          background-color: #007fad;

          &:first-child {
            border-radius: 10px 0 0 0;
            border-right: 1px solid #d0e0e7 !important;
          }

          &:last-child {
            border-radius: 0 10px 0 0;
          }
        }

        td {
          &:first-child {
            border-right: 1px solid #d0e0e7 !important;
          }
        }

        .cloumnButtonBlock {
          label {
            display: none;
          }
        }
      }
    }
  }

  .personalDetailsUpload {
    .ducumentUploadBlock {
      .documentUploadContent {
        .documentUpload {
          display: flex;
          justify-content: center;
          align-items: center;
          width: auto;
          margin-top: 7px;
          height: 32px;
        }

        .uploadedInfo {
          height: 35px;
          line-height: normal;
          margin-top: 0 !important;

          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  h1 {
    color: #01314a;
    font-size: 14px;
    margin: 0px;
  }

  .greenBorderButton {
    margin-right: 10px;
  }

  .addMoreAction {
    border-bottom: 1px solid #94cd00;
    color: #94cd00;
    cursor: pointer;
    margin-left: 10px;
  }
}

/**********************************************Contact   Details******************************************************************/

.contactDetailsBlock {
  .contactDetails {
    padding-bottom: 15px;
  }

  h1 {
    color: #01314a;
    font-size: 14px;
    margin: 0px 0px 10px 0px;
  }

  .greenBorderButton {
    margin-right: 10px;
  }
}

/********************************************previous  Experience******************************************************************/

.previousExperienceBlock {
  .consoleFormBlock {
    position: relative;
  }

  .greenBorderButton {
    margin-right: 10px;
  }

  .previousBlock {
    padding: 10px 0px;
  }

  .ducumentUploadBlock .documentUpload {
    border: 1px dashed #94cd00;
    border-radius: 10px;
    text-transform: none;
    color: #94cd00;
    height: 45px;
    background: transparent;
    box-shadow: none;
  }

  .ducumentUploadBlock {
    display: flex;
    justify-content: start;
  }

  .monthAndYearDatePicker .monthPicker .MuiInputBase-root-MuiOutlinedInput-root {
    // border-radius: 10px 0px 0px 10px !important;
    // background: white;
    // border: 1px solid #ccd6db !important;
  }

  .monthAndYearDatePicker .yearPicker .MuiInputBase-root-MuiOutlinedInput-root {
    // border-radius: 0px 10px 10px 0px !important;
    // background: white;
    // border: 1px solid #ccd6db !important;
  }

  h1 {
    color: #01314a;
    font-size: 14px;
    margin: 0px 0px 10px 0px;
  }

  .addMoreAction {
    border-bottom: 1px solid #94cd00;
    color: #94cd00;
    cursor: pointer;
    position: relative;
    top: 15px;
  }

  .closeButtonForInput {
    display: flex !important;
    align-items: end !important;
    padding: 0 10px !important;
    width: 30px !important;
    height: 30px !important;
    position: absolute;
    right: 15px;

    svg {
      width: 20px;
      padding: 5px;
      height: 20px;
      border-radius: 20px;
      background: rgba(250, 35, 74, 0.1);
      fill: #fa234a;
      border: none;

      &:hover {
        fill: #fa234a;
        background: #f9c3cd;
      }
    }
  }
}

///moreactionbtn
.more_actions {
  border-radius: 8px;
  position: absolute;
  background-color: #ffffff;
  padding: 20px;
  top: 50px;
  z-index: 1;
  right: 40%;
  width: 180px;
  box-shadow: 0px -3px 20px -6px darkslategrey;

  &:before {
    content: " ";
    height: 0;
    position: absolute;
    width: 0;
    top: -20px;
    right: 40px;
    border: 10px solid transparent;
    border-bottom-color: #ffffff;
  }
}

.more_orderList {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  line-height: 35px;
  color: #8f9592;

  svg {
    margin-right: 5px;
    width: 20px;
    margin-left: 5px;
  }
}

.Invisible {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: -9999;
}

.custom_view {
  border: none !important;
  background: none !important;
  padding: 0;
}

/************************************************Official Information for HRMS ADD EMPLOYEE*********************************************************************/

/*********************work Details for add employee***********************/
.workDetailsBlock {
  h1 {
    color: #01314a;
    font-size: 14px;
    margin: 10px 0px 10px 0px;
  }

  .workDetails {
    padding: 0px 0px 10px 0px;
  }

  .greenBorderButton {
    margin-right: 10px;
  }
}

/*****************************salary Structure***************************************************/
.salaryStructure {
  h1 {
    color: #01314a;
    font-size: 14px;
    margin: 10px 0px 10px 0px;
  }

  .greenBorderButton {
    margin-right: 10px;
  }
}

/************************************Bank Details******************************************/
.bankDetails {
  h1 {
    color: #01314a;
    font-size: 14px;
    margin: 10px 0px 10px 0px;
  }

  .greenBorderButton {
    margin-right: 10px;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: 1px solid #ccd6db;
  }
}

/************************************upload Documents******************************************/
.uploadDocuments {
  .addMoreAction {
    border-bottom: 1px solid #94cd00;
    color: #94cd00;
    cursor: pointer;
    position: relative;
    // top: 15px;
    margin-left: 10px;
  }

  .greenBorderButton {
    margin-right: 10px;
  }

  .gridContainer .gridComponent table th:first-child {
    border-right: 1px solid #ccd6db;
  }

  .gridContainer .gridComponent table td:first-child {
    border-right: 1px solid #ccd6db;
  }

  .gridContainer .gridComponent table td {
    padding: 9px 10px 9px !important;
  }

  .gridContainer .gridComponent table td {
    padding: 5px 10px;
    border-right: 1px solid #d0e0e7;
    color: #969c99;
  }

  .gridContainer .gridComponent table {
    /* width: 100%; */
    border-collapse: collapse;
    font-size: 14px;

    label {
      display: none;
    }

    th {
      background-color: #007fad;

      &:first-child {
        border-radius: 10px 0 0 0;
      }

      &:last-child {
        border-radius: 0 10px 0 0;
      }
    }
  }
}

/***********************************Onboarding*********************************************/
.onboardingContainerBlock {
  table {
    th {
      background-color: #007fad;

      &:first-child {
        border-radius: 10px 0 0 0;
      }

      &:last-child {
        border-radius: 0 10px 0 0;
      }
    }
  }
}

/************************************shift Timings******************************************/
.shiftTiming {
  h1 {
    color: #01314a;
    font-size: 14px;
    margin: 10px 0px 10px 0px;
  }

  .greenBorderButton {
    margin-right: 10px;
  }
}

/***********************************Leave Policy******************************************/
.leavePolicy {
  h1 {
    color: #01314a;
    font-size: 14px;
    margin: 10px 0px 10px 0px;
  }

  .greenBorderButton {
    margin-right: 10px;
  }

  .companyLeaves {
    margin: 25px 0px;

    .gridContainer .gridComponent table td:first-child {
      border-right: 1px solid #dee7ec;
    }

    .gridContainer .gridComponent {
      table {
        th {
          background-color: #007fad;

          &:first-child {
            border-radius: 10px 0 0 0;
          }

          &:last-child {
            border-radius: 0 10px 0 0;
          }
        }

        td {
          padding: 5px 10px;
          border-right: 1px solid #d0e0e7;
          color: #01314a;
          line-height: 30px;
        }
      }
    }
  }
}

/***********************************Medical enrollment******************************************/
.medicalEnrollment {
  h1 {
    color: #01314a;
    font-size: 14px;
    margin: 10px 0px 10px 0px;
  }

  .greenBorderButton {
    margin-right: 10px;
  }
}

/***********************************provident fund******************************************/
.providentFund {
  h1 {
    color: #01314a;
    font-size: 14px;
    margin: 10px 0px 10px 0px;
  }

  .greenBorderButton {
    margin-right: 10px;
  }
}

/***********************************Leave Request******************************************/
.applyLeave {
  .greenBorderButton {
    margin-right: 10px;
  }

  .leaveRequest {
    margin-bottom: 10px;
  }

  .documentUpload {
    margin-top: 10px;
  }
}

/***********************************Create Request******************************************/
.createRequest {
  .greenBorderButton {
    margin-right: 10px;
  }
}

/***********************************Create ExpenseBlock******************************************/
.createExpenseBlock {
  .greenBorderButton {
    margin-right: 10px;
  }

  .expenseBlock {
    margin-bottom: 10px;
  }

  .consoleFormBlock {
    background-color: #ebfaff;
    border-radius: 10px;
    padding: 20px;
    border: 2px solid #d7f5ff;
    margin-bottom: 15px;
  }
}

/***********************************Create Advance Block******************************************/
.createAdvanceRequest {
  .greenBorderButton {
    margin-right: 10px;
  }
}

/***********************************pay roll data******************************************/
.payRollData {
  h1 {
    color: #01314a;
    font-size: 14px;
    margin: 10px 0px 10px 0px;
  }

  .consoleFormButtonBlock {
    padding: 20px 0px 0px;
  }

  .ducumentUploadBlock .documentUpload {
    border: 1px dashed #94cd00;
    border-radius: 10px;
    //margin-top: 20px;
    text-transform: none;
    color: #94cd00;
    height: 47px;
    background: transparent;
    box-shadow: none;
    margin-top: 13px !important;
  }

  .uploadAndDownloadBtn {
    margin-top: 10px;
  }

  .editableTableContainer {
    margin-top: 10px;
  }

  .react-bs-table-sizePerPage-dropdown {
    display: none;
  }

  .consoleAdormentImgBlock {
    img {
      cursor: pointer;
    }
  }

  .container {
    margin-top: 35px;
  }
}

/***********************************create New******************************************/
.createNew {
  .greenBorderButton {
    margin-right: 10px;
  }
}

/***********************************mark Attendance******************************************/
.markAttendance {
  .greenBorderButton {
    margin-right: 10px;
  }

  .markAttendanceContent {
    margin-top: 15px;
  }
}

/******************************************sales module for quotation*********************************************************************/
.createQuotationContainer {
  .iconsBlock .MuiStepper-root {
    width: 66% !important;
    margin: 0px auto !important;
  }
}

/*******************sales Modules Invoce Submoudle*********************/

.taxPayableContent {
  display: flex;
  margin-top: 9px;
}

/******************************************uom master im master module*******************************/
// .uomMasterContainer {
//   .greenButton {
//     padding: 22px 40px;
//     margin-top: 4px;
//     width: 260px;
//   }
// }
.BranchMasterContainer {
  .branchMasterUploadButton .greenButton {
    padding: 22px 40px;
    margin-top: 4px;
    width: 260px;
  }
}

.filterBtn {
  margin-left: 10px;

  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    min-width: 40px !important;
  }
}

/********Item Master &  Branch Master  & Bill Pass**********/
.itemMasterContainer,
.BranchMasterContainer,
.packageMasterContainer,
.billPassContainer,
.SupplierMasterContainer {
  .ducumentUploadBlock {
    display: block;

    .documentUpload {
      width: 100%;
    }
  }

  .d-flex {
    display: block;
  }
}

/**************************HRMS*****************************/
.note {
  .informationText {
    margin: 0 15px;
    font-size: 13px;
    color: red;
  }
}

.formula {
  font-size: 13px;
  color: #004b73;

  span {
    word-break: break-word;
  }
}

.hrmsButtons .button {
  margin: 0 5px;
}

.offerLetterButtons {
  .greenButton {
    margin: 0 5px;
  }
}

.addFormulaBlock {
  display: flex;
  justify-content: end;
}

.closeButtonForInput {
  display: flex !important;
  align-items: end !important;
  padding: 10 10px !important;
  width: 40px !important;
  height: 45px !important;

  svg {
    width: 20px;
    padding: 5px;
    height: 20px;
    border-radius: 20px;
    background: rgba(250, 35, 74, 0.1);
    fill: #fa234a;
    border: none;

    &:hover {
      fill: #fa234a;
      background: #f9c3cd;
    }
  }

  &:hover {
    background-color: transparent !important;
  }

  .cardPagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
  }

  .cardPagination>.active>a {
    color: #044e75;
    padding: 2px 10px;
    margin: 0 3px;
    border-radius: 5px;
    background: #e2e9ed;
  }

  .cardPagination>li>a {
    outline: none;
    cursor: pointer;
    padding: 2px 10px;
    margin: 0 3px;
    color: #a5bdcc;
  }

  .cardPagination>.active>span,
  .cardPagination>.active>a,
  .cardPagination>.active>a:hover,
  .cardPagination>.active>span:hover,
  .cardPagination>.active>a:focus,
  .cardPagination>.active>span:focus {
    // background-color: #47ccde ;
    background: #e2e9ed;
    border-color: #47ccde;
    outline: none;
  }

  // .pagination > li > a, .pagination > li > span{
  //   color: #47ccde
  // }
  .cardPagination>li:first-child>a,
  .cardPagination>li:first-child>span,
  .cardPagination>li:last-child>a,
  .cardPagination>li:last-child>span {
    border-radius: unset;
  }

  .packageMasterContainer {
    .consoleAdormentBlock {
      button {
        margin: 0 !important;
      }
    }
  }

  /*******************Procurement****************************/
  .calculationBlockTable {
    display: flex !important;

    table {
      float: right;

      td {
        padding: 0 10px;
        font-size: 13px;

        &:first-child {
          text-align: right;
        }

        display: flex;
        justify-content: end;
      }
    }
  }

  .calculationBlock {
    display: flex;
    justify-content: end;
  }
}

/*Warehouse Master*/
.css-3iigni-container {
  .css-1insrsq-control {
    line-height: 35px;
    border-radius: 10px;
    margin-top: 5px;
    background-color: #c8dce4;
  }
}

/*Dashboard Place Holder section*/
.dashboardPlaceholderSection {
  text-align: center;
  margin-top: 10%;

  p {
    font-size: 20px;
    color: #3e3d54;
    font-family: "poppins_bold";
  }
}

/************Jute Indent**************/
.juteIndent {
  .consoleAdormentBlock {
    .MuiInputAdornment-root {
      span {
        margin: 0 10px;
      }
    }
  }

  .MuiButton-text {
    display: none;
  }
}

/********Common Upload Styles********/
.personalDetailsUpload {
  .ducumentUploadBlock {
    .documentUploadContent {
      label {
        margin-bottom: 8px;
        font-size: 13px;
        color: #808080;
        font-family: "poppins-regular";
      }

      .documentUpload {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        // margin-top: 25px;
        height: 35px;
      }

      .uploadedInfo {
        line-height: normal;
        margin-top: 25px;
        height: 35px;

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.juteFilterBlock {
  .juteFilterDateBlock {
    display: block !important;

    .consoleTextFieldBlock {
      margin-bottom: 10px;

      .fromDatePicker {
        border-radius: 10px;
        height: 25px;
        border: 1px solid #ccd6db;
        margin-top: 5px;
        display: flex !important;
        justify-content: space-between;
        padding: 10px;
        // background-color: #ffffff;

        input {
          border: none;
          width: 100%;
          outline: none;
          
        }

        .MuiIconButton-edgeEnd {
          color: #bfccd2 !important;
        }
      }
    }
  }

  // .consoleTextFieldBlock {
  //   margin-bottom: 0px !important;
  //   .juteFilterDateBlock {
  //     display: block !important;
  //     border-radius: 10px;
  //     height: 25px;
  //     border: 1px solid #ccd6db;
  //     margin-top: 5px;
  //     margin-right: 5px;
  //     display: flex !important;
  //     justify-content: space-between;
  //     padding: 10px;
  //     background-color: #ffffff;

  //     .fromDatePicker {
  //       border-radius: 10px;
  //       height: 25px;
  //       border: 1px solid #ccd6db;
  //       margin: 0px !important;
  //       display: flex !important;
  //       justify-content: space-between;
  //       padding: 10px;
  //       background-color: #ffffff;
  //       input {
  //         border: none;
  //         width: 100%;
  //         outline: none;
  //       }
  //       .MuiIconButton-edgeEnd {
  //         color: #bfccd2 !important;
  //       }
  //     }
  //   }
  // }
}

/**************************/
.ledgerWiseTable {
  th {
    width: 20% !important;
  }

  td {
    white-space: inherit !important;
  }
}

.ledgerWiseTableCalculation {
  td {
    border-left: 0.5px solid #d0e0e7;

    &:first-child {
      border: none;
    }
  }

  .gridComponentNoBorder {
    padding: 0 !important;
  }
}

/****************************/
.juteConversion {
  .consoleAdormentBlock {
    .inputAdornmentText {}
  }
}

.finishingEntriesContainer {
  .employeeDetailsContent {
    margin: 16px 0 0;
  }

  .employeeDetailsBlock {
    font-size: 14px;

    span {
      font-weight: bold;
      color: #004b73;
    }

    .employeeDetailsContent {
      margin: 20px 0 15px 0;
    }

    // margin: 10px;
  }
}

/*Cuts and Jugar*/
.cutsAndJugarTable {
  width: 70%;
  margin-top: 20px;

  .formulaTable {
    border: none;
  }

  th {
    text-align: left;
    padding: 5px 10px;
    background: #004b73;
    color: #ffffff;
    font-weight: normal;
    height: 40px;

    &:first-child {
      border-radius: 10px 0 0 0;
    }

    &:last-child {
      border-radius: 0 10px 0 0;
    }
  }

  td {
    padding: 5px 10px;
    width: 30%;
    vertical-align: middle;

    &:first-child {
      width: 15%;
    }
  }
}

.machineandQuantityTable {
  width: 100%;
  margin-top: 20px;

  .formulaTable {
    border: none;
  }

  th {
    text-align: left;
    padding: 5px 10px;
    background: #004b73;
    color: #ffffff;
    font-weight: normal;
    height: 40px;

    &:first-child {
      border-radius: 10px 0 0 0;
    }

    &:last-child {
      border-radius: 0 10px 0 0;
    }
  }

  td {
    padding: 5px 10px;
    width: 30%;
    vertical-align: middle;

    &:first-child {
      width: 15%;
    }
  }
}

.tooltipClassBorder {
  border: 2px solid #04749c !important;
  border-radius: 10px !important;

  .__react_component_tooltip {
    border-radius: 10px;

    display: inline-block;

    font-size: 13px;

    left: -999em;

    opacity: 0;

    position: fixed;

    pointer-events: none;

    transition: opacity 0.3s ease-out;

    top: -999em;

    visibility: hidden;

    z-index: 999;
  }
}

// .css-31ca4x-MuiPickersFadeTransitionGroup-root {
//   display: none !important;
//   height: 0px !important;
//   margin-top: 0px !important;
//   margin-bottom: 0px !important;
//  .MuiPickersCalendarHeader-root css-nk89i7-MuiPickersCalendarHeader-root{
//   display: none !important;
//   height: 0px !important;
//   margin-top: 0px !important;
//   margin-bottom: 0px !important;
//  }
// }
// .MuiPickersCalendarHeader-root{
//   display: none !important;
//   height: 0px !important;
//   margin-top: 0px !important;
//   margin-bottom: 0px !important;
// }
.credentials-container {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .buttons-container {
    display: flex;
    margin-bottom: 20px;
  }
  
  .gst_button {
    background: transparent;
    margin: 5px !important;
    border: 2px solid #007fad !important;
    border-radius: 25px !important;
    color: #007fad !important;
    font-size: 13px !important;
    text-transform: capitalize;
    padding: 10px 20px;
  }
  
  .gst_button.active {
    background-color:  #007fad !important;
    color: #ffffff !important;
    font-size: 13px !important;
    text-transform: capitalize;
    padding: 10px 20px;
  }
  
  .credentials-card {
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column; /* Adjust card layout to vertical */
  }
  
  .card-header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .card-body {
    margin-bottom: 15px;
    flex-grow: 1; /* Allow body to expand */
    display: flex;
    flex-direction: column; /* Adjust body layout to vertical */
  }
  
  .label-input-group {
    display: flex;
    align-items: center;
    color: #808080 !important;
  }
  
  .gst_input_fields {
    flex: 2; /* Adjust input field width */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  
  .request-otp-button{
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 400;
    background-color:#ccebed;
    color: #13b6c2;
    border: none;
    border-radius: 10px;
    margin-top: 10px; /* Add margin at the top of the buttons */
  }
  .submit-otp-button,
  .update-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color:#94cd00 !important;
    color: #fff;
    border: none;
    border-radius: 10px;
    margin-left: auto; /* Align button to the right */
    margin-top: 10px; /* Add margin at the top of the buttons */
  }
  
  .submit-otp-button {
    background-color: #ccebed;
  }
  .request-otp-button:hover {
    background-color: #0056b3;
    color: #ffffff;
  }

.otp-request-container {
    display: flex;
    align-items: center;
  }
  
  .otp-request-container label {
    margin-right: 10px;
  }
.mandatory {
    color: red;
    margin-left: 5px;
  }
  

.notificationContainer{
    border-radius: 50%;
    background-color:#cfe8f0;
    display:flex;
    justify-content: center;
    align-items:center;
    height:45px;
    margin-right:10px!important;
   
}
.notification{
    background-color: white;
    width: 350px;
    border-radius: 5px;
    box-shadow: 0 0 6px 1px #d9d9d9;
    position: absolute;
    right: 0;
    top: 45px;
    z-index: 1;
}
.notificationIconContainer{
    justify-content: center;
    border-radius:50%;
    background:#cfe8f0;
    width:32px;
    height:32px;
    display:flex;
    align-items:center;
    svg{
        fill:#377da9;
    }
}
.notificationIconBlock{
    width:16px;
    height:18px;
    margin:0px;
    padding:0px;
}
.Notifications{
    width: 80%;
    margin-left: 21px;
    h3{
        margin:0px;
        color:#01314A;
        font-size:14px;
    }
    p{
        margin:0px;
        font-size:10px;
        color:#01314A;
    }
}
.notification_content{
    display: flex;
padding: 15px;
align-items: center;
border-bottom: 1px solid #C7D7DF;
}
.CloseButton{
    background-color: transparent;
    border:transparent;
}
.notification-block{
  margin-right: 19px;
}
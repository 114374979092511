// .customDropdown {
//   label {
//     margin-bottom: 8px;
//     font-size: 13px;
//     color: #808080;
//     font-family: "poppins-regular";
// }
//   select {
//     margin-top:5px;
//     border-radius: 10px !important;
//     padding: 11px 10px;
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none; /* Remove default arrow */
//     background-image: url(../images/dropdownArrow.png); /* Add custom arrow */
//     background-repeat: no-repeat;
//     background-position: calc(100% - 15px) calc(100% - 15px);
//     background-size: 15px;
//     font-family: "poppins-regular";
//     font-size: 15px;
//     border: 1px solid #b3c1c9;
//     &:focus-visible {
//       outline: none;
//     }
//   }
// }

.customDropdown {
  label {
    margin-bottom: 8px;
    font-size: 13px;
    color: #808080;
    font-family: "poppins-regular";
  }
  .react-select__control{
    margin-top: 5px;
    border: 1px solid #ccd6db;
    border-radius: 10px !important;
    line-height: 33px;
    box-shadow: none;
    &:focus-visible {
      outline: none;
    }
    .react-select__menu-portal{
      white-space: break-spaces;
      word-break: break-word;
    }
  }
  .react-select__indicator-separator{
    display: none;
  }
  .react-select__placeholder {
    color: #ccd5da;
  }
  select {
    margin-top: 5px;
    border-radius: 10px !important;
    padding: 11px 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; /* Remove default arrow */
    background-image: url(../images/dropdownArrow.png); /* Add custom arrow */
    background-repeat: no-repeat;
    background-position: calc(100% - 15px) calc(100% - 15px);
    background-size: 15px;
    font-family: "poppins-regular";
    font-size: 15px;
    border: 1px solid #b3c1c9;
    &:focus-visible {
      outline: none;
    }
  }
}

.addItemsBlockContainer {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.searchBlockContainer {
  border-radius: 8px;
  outline: none;
  padding: 10px 10px;
  float: right;
  border: none;
  background: #e2f2f7;
}

.editableTableContainer {
  margin-top: 20px;

  .gridComponent {
    overflow-y: auto;

    table {
      th {
        background-color: #007fad;

        &:first-child {
          border-radius: 10px 0 0 0;
        }

        &:last-child {
          border-radius: 0 10px 0 0;
        }
      }

      td {
        .inputBlockContainer {
          min-width: 130px;
        }
      }
    }

    .MuiInputAdornment-root {
      span {
        font-size: 14px;
      }
    }

    .MuiInputBase-adornedEnd {
      button {
        display: none;
      }
    }

    .customDropdown {
      // border: none;
      background: #e2f2f7;
      outline: none;
      border-radius: 10px;
      border: 1px solid #93bcc9;
      // &:hover {
      //   border: none;
      // }


    }

    .consoleTextFieldBlock {
      margin: 10px 5px 0;
    }

    .consoleDatePicker {
      margin: 0;
      padding: 7px;
      background: #e2f2f7;
      border: 1px solid #93bcc9;

      input {
        background: #e2f2f7;
      }
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
      scrollbar-width: thin;
    }

    &::-webkit-scrollbar {
      width: 9px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a8afb2;
      border-radius: 20px;
      height: 6px;
      border: transparent;
    }

    .consoleFormContainer {
      .uploadedInfo {
        background: #efefef;
        border-radius: 15px;
        margin-top: 20px;
        padding: 5px;
        height: 30px;
        display: flex;
        align-items: center;

        span {
          font-size: 13px;
        }

        .tickIcon {
          height: 15px;
          margin-right: 5px;
        }

        .deleteButton {
          width: 20px;

          img {
            width: 30px;
          }
        }

        .MuiButton-root {
          height: 20px;
          width: 20px;
          margin-left: 10px;
        }
      }
    }
  }

  .addMoreAction {
    border-bottom: 1px solid #94cd00;
    color: #94cd00;
    cursor: pointer;
    margin-left: 10px;
    font-size: 14px;
  }

  .custom-control-input {
    border-radius: 7px;
    padding: 5px;
    border: 1px solid #a6d0e2;
  }

  .addItemsContainerBlock {
    margin-top: 50px;
  }

  .createInwardAddDetailsBlock {
    color: #bbbbbb;
    font-size: 15px;
  }

  .addButtonBlock {
    background: #346785;
    color: white;
    padding: 8px 48px;
    margin: 10px 1px;
    border: none;
    border-radius: 8px;
  }

  .inputBlockContainer {
    width: 100%;
    // border: none;
    padding: 11px 0px 11px 5px;
    background: #e2f2f7;
    border: 1px solid #93bcc9;
    border-radius: 8px;
    outline: none;

    &hover {
      border: none;
      outline: none;
    }
  }

  .deleteDisplayButton {
    // border: none;
    background: #dfecf1;
    border-radius: 8px;
    padding: 2px 2px;
    border: 1px solid #007fad;
  }

  .checkboxBlock {
    margin-top: 2px;
  }

  .dropdownBlockContainer {
    width: 100%;
    border: none;
    background: #e2f2f7;
    // border-right: 1px solid red;
    margin: 0 !important;
  }
  .fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: large;
    text-rendering: auto;
    padding: 4px 5px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  // .dynamicSelectBlock .css-1s2u09g-control {
  //   border: none;
  //   background: #e2f2f7;
  //   outline: none;
  //   // width:85px;

  //   &hover {
  //     border: none;
  //   }
  // }
  .dynamicSelectBlock {
    display: flex;
    align-items: center;

    margin: 0 !important;
  }

  .quantityDropdownBlock .selectAndTextfield {
    .tableDisplayBlock {
      padding: 0 !important;

    }

    .customDropdown {
      .react-select__control {
        line-height: normal !important;
        margin-top: 0 !important;
        border: none;
        min-width: 250px;
      }

      .react-select__menu-portal {
        white-space: break-spaces;
        word-break: break-word;
      }

      .react-select__placeholder {
        color: white !important
      }


    }

    // .customDropdown .css-1s2u09g-control {
    //   border: 1px solid #e2f2f7;
    //   margin-top: 0 !important;
    //   // border-radius: 0 10px 10px 0 !important;
    //   // border-right: 1px solid #bbbbbb;
    // }
    // .customDropdown .css-1pahdxg-control {
    //   border: 1px solid #e2f2f7;
    //   margin-top: 0 !important;
    //   border-radius: 0 10px 10px 0 !important;
    //   // border-right: 1px solid #bbbbbb;
    // }
    // .inputBlockContainer {
    //   border-radius: 0 10px 10px 0 !important;
    // }
    // .css-319lph-ValueContainer {
    //   padding: 0px 2px !important;
    //   width: 40px;
    // }
    // .css-tlfecz-indicatorContainer {
    //   padding: 08 !important;
    // }
    // .css-6j8wv5-Input {
    //   margin: 0 !important;
    //   padding-bottom: 0 !important;
    // }
  }

  .gridContainer .gridComponent {
    padding-top: 0px !important;
  }

  .gridContainer .gridComponent table td {
    padding: 9px 10px 9px !important;
  }

  .gridContainer .gridComponent table th:first-child {
    border-right: 1px SOLID #c7d7df !important;
  }

  .gridContainer .gridComponent table {
    font-size: 14px !important;
  }

  .tableRowDataContainer {
    display: flex;
    justify-content: center;
  }

  .EditableCellBlock {
    position: relative;
  }

  .toolTip {
    position: absolute;
    bottom: 9px;
    right: 0px;
    width: 15px;
    cursor: pointer;
  }

  .consoleAdormentBlock {
    .MuiFormControl-root {
      margin-top: 0;

      .MuiOutlinedInput-root {
        height: 40px;
        min-width: 140px;
      }
    }

    input {
      padding: 7px 10px;
    }
  }
}

// Sales Invoice Type Select 
.invoice_type_block {
  .react-select__control {
    border-radius: 10px;
    min-height: 50px !important;
    background-color: #01314A;
  }

  .react-select__menu-portal {
    white-space: break-spaces;
    word-break: break-word;
    background-color: #01314A !important;
  
  }
  .react-select__placeholder {
    color: white !important
  }
  .react-select__indicator{
    color: white;
  }
  .react-select__indicator-separator{
    display: none;
  }
  .react-select__single-value{
    color: white;

  }
}
.indentHeaderBlock{
    display:flex;
    justify-content:space-between;
    
}
.createIndentContainerBlock{
    display:flex;
    align-items:center;
}
.backIconPic{
    color:#346785;
    font-size:28px;
    margin-right:5px;
}
.createIndentBlock{
    color:#346785;
    text-align:left;
    font-size:18px;
   margin:0px;
}
.gridAddItemsBlock{
        color:#346785;
        text-align:left;
        font-size:16px;
}
.expandBlock{
        width:40px;
        
}

.indentContainerBlock .MuiStepLabel-label{margin:0px!important;}
// .indentContainerBlock .MuiStepConnector-root{
//     top:28px!important;
    
// }
.indentContainerBlock .MuiStepLabel-root{
    margin-top:0px!important;
}
.indentContainerBlock .MuiStepper-root MuiStepper-horizontal{
    align-items:center;
    
}
.indentContainerBlock{
    display:flex;
    margin:0;

 }
.indentContainerNavBlock{

    width:200px;
    margin:0px;
}
.indentContainerMainBlock{
  width:100%;
}

.notifictionPicBlock{
    width:20px; 
}
.databasePicBlock{
    width:20px;
    margin-top:5px;
    margin-right:9px; 
}
.notificationDbIconBlock{
    display:flex;
    align-items:center;
}
.notificationPicBlockBackground{
    background:#dfecf1;
    border-radius:50%;
   justify-content:center;
   display:flex;
   height:40px;
   width:42px;
   align-items:center;
margin-right:20px;
}
.iconsPurchaseBlock img{height:40px;}
.iconsPurchaseBlock{
    margin:5px 0px;
     .MuiStepper-root{
        width: 40% !important;
        margin: 0px auto !important;
        font-size: 14px;
    }
}










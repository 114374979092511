.holidayCalendar {
  .fc-daygrid-body {
    z-index: 0 !important;
  }
}
.attendanceCalendar {
  .fc-daygrid-dot-event {
    padding:10px 0;
    .fc-daygrid-event-dot,
    .fc-event-time {
      display: none;
    }
    .fc-event-title {
      margin: 0 0 0 10px;
      white-space: break-spaces;
      font-size: 11px;
      font-weight:100;
    }
  }
}
.holidayTable {
  .gridContainer {
    .gridComponent {
      th {
        background-color: #007fad;
        &:first-child {
          border-radius: 10px 0 0 0 !important;
        }
        &:last-child {
          text-align: center !important;
          border-radius: 0 10px 0 0 !important;
        }
      }
      td {
        text-align: center;
        &:first-child {
          text-align: left;
        }
        svg {
          cursor: pointer;
        }
      }
    }
  }
}
.holidayPopup {
  h5 {
    margin: 0 0 20px 0;
    color: #007fad;
  }
  .MuiGrid-root {
    button {
      float: right;
    }
  }
  .MuiGrid-item {
    label {
      // font-weight: bold;
      color: #808080;
      .starcolor {
        color: red;
      }
    }
    font-size: 13px;
  }
  .TextField {
    padding: 10px;
  }
}

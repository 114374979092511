.changePasswordContainer {
    .resetPasswordFormValidation {
      width: 30%;
    }
    .resetPasswordTextField {
      align-items: center;
      justify-content: center;
      width: 100%;
      position: relative;
    }
    .resetPasswordTextField .displayIcon {
      position: absolute;
      cursor: pointer;
      justify-content: space-between;
      top: 10px;
      right: 0px;
      color: #bbbbbb;
    }
    .resetPasswordErrorMessage {
      color: red;
      font-size: 12px;
      margin: 10px 0px;
    }
    ::-ms-reveal {
      display: none !important;
    }
  
    .changePasswordContainerBlock {
      text-align: left;
    }
    h1 {
      font-family: 'poppins_Regular';
      font-size: 16px;
      margin: 0;
      padding: 10px 17px;
      color: #10577c;
      justify-content: left;
      border-bottom: 1px solid #bbbbbb;
    }
    .changePassword {
      margin: 10px 10px;
    }
    label {
      color: #8e8e8e;
      font-family: 'poppins_Regular';
      font-size: 13px;
      margin-top: 12px;
      margin-bottom: 3px;
    }
    //   .checkboxBlockChangePassword {
    //     display: flex;
    //     align-items: center;
    //   }
    .changePasswordSigned {
      color: #98acb6;
      font-size: 12px;
      font-family: 'poppins_Regular';
      margin-top: 25px;
      align-items: center;
    }
    .changePasswordBlock {
      padding: 10px 10px;
    }
    //   .changePassword input {
    //     padding: 10px 10px;
    //     border: none;
    //     display: flex;
    //     direction: column;
    //     border-radius: 10px;
    //     color: #10577c;
    //     width: 95%;
    //     outline: none;
    //   }
  
    .ChangePasswordBlock {
      background-color: #a0de00;
      border: none;
      text-align: center;
      border-radius: 12px;
      padding: 7px 45px;
      align-items: center;
      display: flex;
    }
    .ChangePasswordBlock span {
      color: white;
      font-family: 'poppins_Regular';
    }
    .changePassword .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
      padding: 10px 10px 10px 0;
    }
    .changePasswordBlock .css-15jlpv2-MuiInputBase-root-MuiInput-root:before {
      border-bottom: none;
    }
    .changePasswordBlock .css-15jlpv2-MuiInputBase-root-MuiInput-root:after {
      border-bottom: none;
    }
    .css-a88p61-MuiInputBase-root-MuiInput-root {
      position: relative;
      border: none;
      outline: none;
    }
    .changePassword .css-1ce9hys-MuiButtonBase-root-MuiCheckbox-root {
      padding: 0px;
  
      margin-right: 7px;
    }
    .css-10knznu-MuiInputBase-root-MuiOutlinedInput-root:hover {
      border: 1px solid #ffffff;
    }
    .changePassword .css-17vbkzs-MuiFormControl-root-MuiTextField-root {
      margin: 0px;
      padding-bottom: 8px;
    }
  }
  
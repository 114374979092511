.employeeAttendence {
  padding: 15px 10px 10px 20px;

  h1 {
    text-align: left;
    font-size: 18px;
    color: #01314a;
    margin: 10px 0 6px 6px;
  }
  .partPaymentBlock {
    display: flex;
    align-items: center;
  }
  .customFieldBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
  }

  .partPaymentContent {
    color: #01314a;
    font-size: 18px;
    margin: 5px;
  }
  .attendanceContent {
    position: relative;
    width: 500px;
  }
  .closePayslipBlock {
    border: none;
    top: 10px;
    right: 5px;
    position: absolute;
    background: none;
  }
  .closePayslipBlock:hover {
    opacity: 0.3;
    cursor: pointer;
  }
  .greenBorderButton {
    margin-right: 10px;
  }
  .greenButton {
    padding: 0 56px;
  }
}
.attendanceCalendar {
  width: 99%;
  overflow: hidden !important;
  padding: 0.5%;
  .TextFieldBlock {
    .TextField {
      height: 36px;
      margin-top: 0;
    }
  }
  .fc-toolbar-title {
    color: #01314a;
    font-weight: 100;
    font-size: 15px !important;
  }
  .fc-toolbar.fc-header-toolbar {
    border-bottom: 1px solid #c7d7df;
    padding: 5px 25px;
    margin-bottom: 5px !important;
  }
  .fc-media-screen {
    box-shadow: 0px 0px 16px -8px #999999;
    border-radius: 15px;
    margin-top: 20px;
  }
  .fc .fc-button-primary {
    background-color: #94cd00;
    border: none;
  }
  .fc-scroller-liquid-absolute {
    overflow: hidden !important;
  }
  .fc-view-harness-active {
    border-radius: 10px;
  }
  .fc .fc-daygrid-day-top {
    flex-direction: row;
    background-color: #ffffff;
  }
  .fc-theme-standard .fc-scrollgrid {
    border: none;
  }
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: none;
    background-color: #ffffff;
  }
  .fc th {
    .fc-scrollgrid-sync-inner {
      color: #01314a;
      text-align: left;
      line-height: 50px;
      margin: 0 25px;
      text-transform: uppercase;
      font-size: 20px;
    }
  }
  .fc .fc-button-primary:disabled {
    background-color: #ffffff !important;
    border-radius: 10px !important;
    color: #94cd00 !important;
    font-size: 15px !important;
    border: 2px solid #94cd00 !important;
  }
  .fc .fc-button-primary:focus {
    box-shadow: none;
  }
  .fc-daygrid-day.fc-day-today {
    background: none !important;
    .fc-daygrid-day-frame {
      background: #daecff;
    }
  }
  .fc-daygrid-day-frame {
    position: relative;
    min-height: 100%;
    border-top: 1px solid #c7d7df;
    margin: 0 25px;
    text-align: left;
  }
  .fc-daygrid-day-number {
    color: #b0b0b0;
  }
  .fc-h-event {
    background-color: #DCFFC0;
    border: none;
    border-left: 3px solid #94cd00;
    border-radius: 0;
    line-height: 30px;
  }
  .fc-event-title {
    color: #01314a !important;
    margin-left: 5px;
  }
}
.iconButtonsHrms {
  display: flex;
  // position: absolute;
  // right: 15px;
  // top: 60px;
  .button {
    margin: 0 5px;
  }
}

.MuiModal-hidden{display: none!important;visibility: visible;}
.gridStatus {
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  padding:10px 20px;
//   temp
margin: 10px;
// temp end
}
.gridStatus.openStatus,
.gridStatus.inprogressStatus,
.gridStatus.raisePendingStatus,
.gridStatus.modifyPendingStatus,
.gridStatus.pendingStatus,
.gridStatus.transferPendingStatus,
.gridStatus.planningStatus,
.gridStatus.requestedStatus {
    color: #f5980b;
    background: #faf4e7;
  }
.gridStatus.approvedStatus,
.gridStatus.availableStatus,
.gridStatus.readyStatus,
.gridStatus.uploadedStatus,
.gridStatus.paidStatus,
.gridStatus.visitedStatus,
.gridStatus.processedStatus,
.gridStatus.dataCollectedStatus,
.gridStatus.offeredStatus,
.gridStatus.joinedStatus
{
  background: #f5fbe6;
  color: #94cd00;
}
.gridStatus.rejectedStatus,
.gridStatus.closedStatus,
.gridStatus.cancelledStatus,
.gridStatus.raiseRejectedStatus {
    background: #ffe9ed;
    color: #fa234a;
}
.gridStatus.invitedStatus,
.gridStatus.supplierApprovalStatus,
.gridStatus.readedStatus,
.gridStatus.lapsedStatus,
.gridStatus.bookedStatus,
.gridStatus.pendingApprovalLevel2Status,
.gridStatus.pendingApprovalLevel3Status,
.gridStatus.pendingApprovalLevel4Status,
.gridStatus.pendingApprovalLevel5Status{
    background:#f0eeff;
    color:#6052ff;
}
.gridStatus.draftedStatus{
    background:#e6edf1;
    color:#004b73;
}
.gridStatus.autoGeneratedStatus,
.gridStatus.lockedStatus,
.gridStatus.joiningStatus{
    background:#d7f2ff;
    color:#23aafa;
}





.LeaveRequestCardContainer {
  .casualLeaveContainer {
    display: flex;
    text-align: left;
    .instructor1 {
      width: 8px;
      height: 8px;
      margin-right: 10px;
      border-radius: 50%;
      padding:3px;
      margin-top:4px;
    }
    h2 {
      font-size: 14px;
      color: #004b73;
      margin: 0px;
    }
    .reason {
      margin: 0px;
      color: #345a6e;
      font-size: 13px;
      text-align: left;
    }
  }
  p {
    font-size: 13px;
    margin: 0px;
    color: #345a6e;
   
  }
  h3 {
    color: #a0de00;
    font-size: 14px;
    margin: 0px;
    text-align: center;
  }
}

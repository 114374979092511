.footerBlock {
  // border-top: 1px solid #d0e0e7;
  // margin-left: 17%;
  // padding:0 1%;
  // margin-top:20px;
  // z-index: -9;
  // position:absolute;
  bottom: 0;
  // height: 60px;
  border-top: 1px solid #d0e0e7;
  margin-left: 17%;
  padding: 0 1%;
  margin-top: 20px;
  z-index: -9;
  p {
    font-size: 11px;
    color: #004b73;
    margin: 15px 0;
  }
}

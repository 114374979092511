.payslipContainerBlock {
  box-shadow:0px 0px 4px 0px #bbbbbb;
  p{
      padding:10px 10px;
       background:#004b73;
       color:white;
       font-size:16px;
       margin:0px;
  }
  h1{
     text-align:center;
      font-size:18px;
      padding:16px 0px;
      color:#000000;
      border-bottom:1px solid #bbbbbb;
      margin:0px;

  }
  .payslipGridContainer{
      padding:10px 10px
  }
  .payslipProfileInfoContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .profileInfoContainerBlock {
    width: 50%;
    margin-right: 10px;
     table {
      td {
      color: black;
      &:last-child {
        border-right: 1px solid #bbbbbb;
        font-size:12px;
      }
      &:first-child {
        border-left: 1px solid #bbbbbb;
        background: #f3f3f3;
        width: 30%;
        font-size:12px;
      }
    }
    tr{
      border-bottom: 1px solid #bbbbbb;
      border-top: 1px solid #bbbbbb;
      &:nth-child(odd) {
        background: white;
      }
    }
  }
  }
  .profileInfoWorkingDaysContainerBlock {
    width: 50%;
     table {
      tr {
      border-bottom: 1px solid #bbbbbb;
      border-top: 1px solid #bbbbbb;
      &:nth-child(odd) {
        background: white;
      }
    }
    td{
      color: black;
      &:last-child {
        border-right: 1px solid #bbbbbb;
        font-size:12px;
      }
      &:first-child {
        border-left: 1px solid #bbbbbb;
        background: #f3f3f3;
        width: 30%;
        font-size:12px
      }
    }
  }
   
  }

  .CommonTableContainerBlock {
   
     table{ 
      font-size: 13px!important;
      th {
      background: #00abdc;
       &:last-child {
        border-radius: 0px !important;
      }
      &:first-child {
        border-radius: 0px !important;
        border-right:1px solid #d0e0e7;
      }
    }
    td{
      color: black;
      &:first-child {
        border-left: 1px solid #d0e0e7;
      }
      &:last-child {
        border-right: 1px solid #d0e0e7;
      }
      &:nth-child(3) {
        border-left: none;
        width:286px;
        border-right:none!important;
      }
    }
    tr{
      border-bottom: 1px solid #d0e0e7;
      &:nth-child(odd) {
        background:white;
      }
    }
  }
    .grossEarningsBlock {
      font-weight: bold;
      font-size: 13px;
    }
  }
}

/*AddingemployeeDisableBlock*/

.addingNewEmployeeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 0px;
  background: #f8f8f8;
  margin-bottom: 10px;
  .personalInfo {
    display: flex;
    align-items: center;
  }
  .numberBlock {
    border-radius: 50%;
    border: 1px solid #678392;
    color: #678392;
    width: 30px;
    height: 30px;
    margin-left: -14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .otherInfo {
    margin-left: 20px;
  }
  .otherInfo h1 {
    color: #01314a;
    margin: 0px;
    font-size: 15px;
  }
  .otherInfo p {
    margin: 0px;
    font-size: 12px;
    color: #999999;
  }
  .verifyAadhaarBlock {
    border: 2px solid #4aa3c4;
    color: #4aa3c4;
    border-radius: 8px;
    padding: 6px 10px;
    margin-right: 10px;
  }
  .verificationBlock {
    display: flex;
    align-items: center;
  }
  .iconContainer {
    width: 30px;
    margin-left: 10px;
  }
}

/*enableBlockAddingEmployee*/

.addingNewEmployeeDisableContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 0px;
  background: #f8f8f8;
  margin: 10px 40px;
  position: relative;
  .personalInfo {
    display: flex;
    align-items: center;
    margin-left:-15px;
    .infoId {
      border-radius: 50%;
      border: 1px solid #678392;
      color: #678392;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      // position: absolute;
      margin-right: 10px;
      // left: -20px;
    }
  }
  .numberBlock {
    border-radius: 50%;
    border: 1px solid #678392;
    color: #678392;
    width: 30px;
    height: 30px;
    margin-left: -14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .otherInfo {
    margin-left: 15px;
  }
  .otherInfo h1 {
    color: #01314a;
    margin: 0px;
    font-size: 15px;
  }
  .otherInfo p {
    margin: 0px;
    font-size: 12px;
    color: #999999;
  }
  .verifyAadhaarBlock {
    border: 2px solid #4aa3c4;
    color: #4aa3c4;
    border-radius: 8px;
    padding: 6px 10px;
    margin-right: 10px;
  }
  .verificationBlock {
    display: flex;
    align-items: center;
    .iconButton {
      margin-left: 15px;
    }
  }
  .iconContainer {
    width: 30px;
    margin-left: 10px;
  }

  .rightClickIconBlock {
    width: 35px;
    margin-right: 10px;
  }
}
/*********************************AddEmployeeeBlock*********************************/

.addEmployeeOrganization {
  width: 80%;
  .organizationField {
    margin: 0px;
    padding-left: 30px;
    color: #999999;
    font-size: 12px;
    padding-bottom: 7px;
  }
  .iconButton {
    border-radius: 50% !important;
    border: 1px solid #b2bcbf;
    padding: 6px;
    width: 25px;
    height: 25px;
    min-width: auto;
    background: transparent;
    color: #b2bcbf;
  }
  .disableList{
   .otherInfo{
    h1{color:#aebdc4;}
    p{color:#e5e5e5;}
   }
   .infoId{border-color:#b8c6cc;color:#b8c6cc;}
   .iconButton{
    border-color: #e7eaeb;
    svg{
      fill:#e7eaeb;
    }
    cursor: not-allowed;}
  }
}

.onboardingContainerBlock {
  margin: 10px 0px;
  table {
    width: 500px;
    td {
      &:first-child {
        border-right: 1px solid #d0e0e7!important;
      }
      
      .TextField,.consoleDatePicker {
          width:350px!important;
      }
      .blueButton{
        width:370px!important;  
      }
    }
  }
}

.lineChartContainer {
  border: 1px solid #d9d9d9;
  padding: 20px;
  border-radius: 10px !important;
  //box-shadow: 0px 0px 15px -5px #999999 !important;
  .lineChartHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    .lineChartBlock {
      h1 {
        color: #004b73;
        font-size: 14px;
        margin: 0;
        height: 30px;
      }
      p {
        color: #4d819d;
        font-size: 11px;
        margin: 0;
      }
      h2 {
        color: #004b73;
        font-size: 18px;
        font-family: "poppins_bold";
        margin: 0;
        height: 30px;
        span {
          font-size: 11px;
          color: #407896;
          font-family: "poppins-regular";
          font-weight: 100;
        }
      }
      .recharts-responsive-container {
        margin-top: 25px;
      }
    }
  }
}

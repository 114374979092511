.buttonsContainer {
  padding: 10px;
  display: flex;
}

.button {
  padding: 7px 20px;
  font-family: "poppins-regular" !important;
  text-transform: none !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  cursor: pointer;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.button.lightBlueButton {
  background-color: #c9e2ec !important;
  color: #007fad !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px;
  border: 1px solid transparent !important;

  &:hover {
    background-color: #99ccde !important;
    border: 1px solid #99ccde !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #007fad;
  }
}

.button.greenButton {
  background-color: #94cd00 !important;
  color: #ffffff !important;
  font-size: 13px !important;
  border: 1px solid transparent !important;

  &:hover {
    background-color: #7eae02 !important;
    border: 1px solid #7eae02 !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #ffffff !important;
  }
}

.button.blueButton {
  background-color: #007fad !important;
  color: #ffffff !important;
  font-size: 13px !important;
  border: 1px solid transparent !important;

  &:hover {
    background-color: #99ccde !important;
    border: 1px solid #99ccde !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #007fad !important;
  }
  &:disabled {
    background: #99ccde !important;
    color: #007fad !important;
  }
}

.button.blueBorderButton {
  background-color: #ffffff !important;
  border-radius: 10px !important;
  color: #007fad !important;
  font-size: 13px !important;
  border: 2px solid #007fad !important;

  &:hover {
    background-color: #e1f3fa !important;
    border: 2px solid #007fad !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #007fad !important;
  }
}

.button.open {
  background-color: rgb(234, 214, 218);
  color: rgb(252, 144, 21) !important;
  font-size: 13px !important;
  font-weight: bold;
  border: transparent !important;
}

.button.approved {
  background-color: #ccebed;
  color: #13b6c2 !important;
  font-size: 13px !important;
  font-weight: bold;
  border: transparent !important;
}

.button.rejected {
  background-color: #ecb8c2;
  color: #fa234a !important;
  font-size: 13px !important;
  font-weight: bold;
  border: transparent !important;
}

.button.paid {
  background-color: #e6f0cc;
  color: #94cd00 !important;
  font-size: 13px !important;
  font-weight: bold;
  border: transparent !important;
}

.button.greenBorderButton {
  background-color: #ffffff !important;
  border-radius: 10px !important;
  color: #94cd00 !important;
  font-size: 13px !important;
  border: 2px solid #94cd00 !important;

  &:hover {
    background-color: #f5fbe6 !important;
    border: 2px solid #94cd00 !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #94cd00 !important;
  }
}

.button.redBorderButton {
  background-color: #ffffff !important;
  border-radius: 10px !important;
  color: #fa234a !important;
  font-size: 13px !important;
  border: 2px solid #fa234a !important;

  &:hover {
    background-color: #f5fbe6 !important;
    border: 2px solid #fa234a !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #fa234a !important;
  }
}

.button.darkBlueButton {
  background-color: #004b73 !important;
  color: #ffffff !important;
  font-size: 13px !important;
  border: 1px solid transparent !important;

  &:hover {
    background-color: #003b5a !important;
    border: 1px solid #003b5a !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #ffffff !important;
  }
}
.button.disabled {
  background-color: #d4eb99 !important;
  border: none;
  cursor: not-allowed;
  font-size: 13px !important;
}
.button.deleteButton {
  background-color: #fa234a !important;
  color: #ffffff !important;
  font-size: 13px !important;
  border: 1px solid transparent !important;

  &:hover {
    background-color: #d8193b !important;
    border: 1px solid #fa234a !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #ffffff !important;
  }
}
.button.editButton {
  background-color: #94cd00 !important;
  color: #ffffff !important;
  font-size: 13px !important;
  border: 1px solid transparent !important;

  &:hover {
    background-color: #7eae02 !important;
    border: 1px solid #7eae02 !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #ffffff !important;
  }
}
.button.blueBorderIconButton {
  background-color: #c9e2ec !important;
  color: #007fad !important;
  font-weight: bold !important;
  font-size: 13px !important;
  border: 1.5px solid #007fad !important;
  min-width: auto !important;
  padding: 10px;
  img {
    margin: 0;
  }
  &:hover {
    background-color: #99ccde !important;
    border: 1.5px solid #007fad !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #007fad;
  }
}

.button.exportButton {
  background-color: #c9e2ec !important;
  color: #007fad !important;
  font-size: 13px !important;
  font-weight: bold;
  border: 1px solid transparent !important;

  &:hover {
    background-color: #99ccde !important;
    border: 1px solid #99ccde !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #007fad !important;
  }
}

.button.gridButton {
  background-color: #004b73 !important;
  border-radius: 50% !important;
  font-size: 13px !important;
  margin: 0 10px !important;
  border: 1px solid transparent !important;
  padding: 10px !important;
  min-width: 0px !important;
  line-height: normal !important;
  width: 2%;
  height: 2%;
  position: absolute !important;
  right: 10px;

  &:hover {
    background-color: #004b73 !important;
    border: 1px solid #004b73 !important;
    box-shadow: none !important;
  }

  img {
    width: 7px;
    height: 9px;
  }
}

.button.gridPlusButton {
  background-color: #004b73 !important;
  border-radius: 50% !important;
  font-size: 13px !important;
  margin: 0 10px !important;
  border: 1px solid transparent !important;
  padding: 10px !important;
  min-width: 0px !important;
  line-height: normal !important;
  width: 25px;
  height: 25px;

  &:hover {
    background-color: #004b73 !important;
    border: 1px solid #004b73 !important;
    box-shadow: none !important;
  }

  img {
    width: 10px;
    height: 9px;
    margin: 0;
  }
}
.button.gridDeleteButton {
  background-color: #004b73 !important;
  border-radius: 50% !important;
  font-size: 13px !important;
  margin: 0 10px !important;
  border: 1px solid transparent !important;
  padding: 10px !important;
  min-width: 0px !important;
  line-height: normal !important;
  width: 25px;
  height: 25px;

  &:hover {
    background-color: #004b73 !important;
    border: 1px solid #004b73 !important;
    box-shadow: none !important;
  }

  img {
    width: 15px;
    height: 15px;
    margin: 0;
  }
}
/*************HRMS Buttons*********/
.button.hrmsApprove,
.button.hrmsOffer,
.button.hrmsAcceptOffer {
  background-color: #f5fbe6;
  color: #94cd00 !important;
  font-size: 13px !important;
  font-weight: bold;
  border: transparent !important;
}

.button.hrmsReject,
.button.hrmsTerminate,
.button.hrmsResign,
.button.hrmsRejectOffer {
  background-color: #ffe9ed;
  color: #fa234a !important;
  font-size: 13px !important;
  font-weight: bold;
  border: transparent !important;
}

.button.hrmsJoin {
  background-color: #d7f2ff;
  color: #23aafa !important;
  font-size: 13px !important;
  font-weight: bold;
  border: transparent !important;
}

.button.hrmsReOpen,
.button.hrmsRetire,
.button.hrmsRelease {
  background-color: #faf4e7;
  color: #f5980b !important;
  font-size: 13px !important;
  font-weight: bold;
  border: transparent !important;
}
/********************Link Button************************/
.linkButtons{
  margin:20px 0;
  .MuiBox-root{
    text-align: center;
    font-size: 14px;
    color:#94CD00;
    text-decoration: underline;
    cursor: pointer;
    &:hover{
      color:#84b800;
    }
  }
}

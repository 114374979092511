.popupContainer {
  .popupButton {
    padding: 0;
    margin: 0;
    border: none;
    height: 400px;
    cursor: pointer;
    width:100%;
    img {
      width: 100%;
    }
  }
}
.closeButton {
  position: absolute;
  right: 5px;
  top: 10px;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  padding: 0 !important;
  line-height: 0;
  //   position: fixed;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 10px !important;
  position: fixed;
}
.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  background: rgba(0, 0, 0, 0.8) !important;
}

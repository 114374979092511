.delAddItem{
    padding: 50px;
}
.delAddItemBtns{
    margin-top: 2rem;
    display: flex;
    justify-content: space-evenly;
}
.delNoBtn{
    background-color: #94cd00 !important;
  color: #ffffff !important;
  font-size: 13px !important;
  border-radius: 10px !important;
  padding: 10px 25px;
  border: 1px solid transparent !important;
  &:hover {
    background-color: #7eae02 !important;
    border: 1px solid #7eae02 !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #ffffff !important;
  }
}
.delYesBtn{
    background-color: #ffffff !important;
  border-radius: 10px !important;
  color: #94cd00 !important;
  font-size: 13px !important;
  padding: 10px 25px;
  border: 2px solid #94cd00 !important;
  cursor: pointer;
  &:hover {
    background-color: #f5fbe6 !important;
    border: 2px solid #94cd00 !important;
    box-shadow: none !important;
    // border-radius: 10px;
    color: #94cd00 !important;
  }
}
.consoleHeader {
  padding: 10px;
}
.consoleHeaderContainer {
  background-color: #f8f8f8 !important;
  border-bottom: 1px solid #efefef;
  padding: 0 1% 0 2%;
  box-shadow: none !important;
  .MuiToolbar-root {
    min-height: 40px;
  }
  .academicDropdown {
    .react-select__control {
      background: transparent;
      border-radius: 0;
      border: none;
      font-size: 13px;
    }
    .react-select__control--is-focused {
      outline: none;
      border: none;
      box-shadow: none;
    }
    .react-select__indicator-separator {
      display: none;
    }
    .react-select__dropdown-indicator {
      display: none;
    }
    .react-select__menu {
      color: #01314A;
      width: 60%;
      font-size: 13px;
    }
    .react-select__menu-portal{
      white-space: break-spaces;
      word-break: break-word;
    }
    .react-select__single-value{
      color: #01314A;
      font-weight: bold;
    }
  }
  .profileSettingBlock {
    &:hover {
      background-color: transparent;
    }
    .MuiAvatar-circular {
      width: 30px;
      height: 30px;
    }
  }
  .billingButton {
    background-color: #c9e2ec;
    box-shadow: none;
    border-radius: 10px;
    color: #007fad;
    text-transform: none;
    font-family: "poppins-regular";
    font-weight: bold;
    font-size: 13px;
    margin: 0 10px 0 0;
    border: 1px solid transparent;
  }
  .MuiContainer-root {
    padding: 0;
  }
  .date_picker {
    background: none;
    border-radius: 25px;
    border: none;
    font-family: "poppins-regular";
    font-size: 15px;
    color: #004b73;
  }
}
.portalMainContainer {
  padding: 0 1% 0 2%;
}
.headerHeadingAndButtonBlock {
  background-color: transparent !important;
  padding: 0 1% 0 2%;
  .MuiGrid-container {
    display: flex;
    justify-content: space-between;
  }
  .MuiGrid-item {
    max-width: 100%;
  }
}
.headerProfilePicBlock {
  .MuiPaper-root {
    // border:1px solid green;
    border-radius: 10px;
  }
  .MuiTypography-root {
    font-size: 13px;
  }
}
.consoleHeadingText {
  font-family: "poppins_bold";
  color: #004b73;
  margin: 0;
  img {
    height: 13px;
    margin-right: 15px;
  }
}
.headerRightBlock {
  display: flex;
  justify-content: end;
  .profileArrowIcon {
    width: 10px;
    margin-left: 10px;
  }
}

.profileContainer {
  padding: 5px 0;
  width: 350px;
  .profileHeader {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 10px;
    padding: 0 10px 10px;
    .MuiAvatar-circular {
      background: #004b73;
      margin-top: 3px;
      margin-left: 5px;
    }
    h3 {
      margin: 0;
      font-size: 15px;
      color: #004b73;
    }
    h6 {
      font-size: 13px;
      color: #1f6184;
      margin: 0;
      font-family: "poppins-regular";
      font-weight: 300;
    }
  }
  .profileFooter {
    border-top: 1px solid #e5e5e5;
    padding: 10px 0 0;
    margin-top: 10px;
    p {
      display: flex;
      align-items: center;
      color: #1f6184;
    }
    img {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }
  .profileLinksBlock {
    // .css-1uwgr7b-MuiTypography-root {
    //   font-size: 13px;
    //   color: #1f6184 !important;
    //   font-family: "poppins-regular";
    // }
    a {
      color: #1f6184 !important;
      text-decoration: none;
    }
  }
}
// .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
//   border-radius: 10px !important;
// }
/***************************listOfAttendanceBlock**************************************/

.listOfAttendanceBlock {
  align-items: center;
  display: flex;
  .greenBorderButton {
    background-color: #ffffff !important;
    border-radius: 10px !important;
    color: #94cd00 !important;
    font-size: 13px !important;
    border: 2px solid #94cd00 !important;
    margin-right: 10px;
    width: 190px;
  }

  .greenButton {
    background-color: #94cd00 !important;
    color: #ffffff !important;
    font-size: 13px !important;
    border: 1px solid transparent !important;
    width: 170px;
  }
}

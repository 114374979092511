.innerNavContainer {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 0px 10px #d9d9d9;
  width: 20%;
  height: 92vh;
  h4 {
    font-family: "poppins-regular";
    padding-bottom: 10px;
    border-bottom: 1px solid #d9e0e4;
    color: #004b73;
    margin-top: 10px;
    // font-size: 15px;
  }
  li {
    padding: 10px 0;
  }
  a {
    padding: 5px 10px;
    border-radius: 10px;
    display: block;
    color: #6693ab;
  }
  a:hover {
    background: #e8f7ff;
    color: #004b73;
  }
  a.active {
    background: #e8f7ff;
    color: #004b73;
  }
}
